import { useEffect } from "react";
import ky from "ky";
import { useRequest } from "./useRequest";
import type { State } from "./useRequest";

async function getRequest<T>(
  url: RequestInfo,
  abortController: AbortController
): Promise<T> {
  return ky
    .get(
      new Request(url, {
        signal: abortController.signal,
      })
    )
    .json();
}

export function useGetRequest<T>(
  url: string,
  validator?: (obj: State<T>) => State<T>
) {
  const [data, send] = useRequest<T>(getRequest);

  useEffect(() => {
    const abortController = new AbortController();
    send(url, abortController);
    return () => {
      abortController.abort();
    };
  }, [send]);

  return validator ? validator(data) : data;
}
