import io from "socket.io-client";
import store from "store/store";
import { receiveNotification } from "store/api/notificationsGet/notificationsGetActions";
import { translateActivityProps } from "store/api/organization/activityLogsGet/activityHelper";
import { env } from "env";
import { getCurrentSession } from "./localStorage";
import { cookieEnabled } from "./apiConfigurator";

const middleware = require("socketio-wildcard")(io.Manager);

let socket = null;
let accessToken = null;

export function setWSAccessToken(newAccessToken) {
  accessToken = newAccessToken;
}

export function closeWSConnection() {
  if (socket) {
    socket.close();
  }
}

export function authWSConnection() {
  if (socket.connected) {
    const session = getCurrentSession();
    const token = cookieEnabled ? accessToken : session.accessToken;

    if (token) {
      socket.emit("authenticate", {
        token,
        userAgent: btoa(window.navigator.userAgent),
      });
    }
  } else {
    socket.connect();
  }
}

export async function initWSConnection() {
  if (socket) {
    socket.close();
  }
  await new Promise((resolve) => {
    let wsUrl =
      env.REACT_APP_WS_URL || `https://socket.${env.REACT_APP_DOMAIN}/ws`;
    const { tenantId } = getCurrentSession();
    socket = io(wsUrl, {
      transports: ["websocket"],
      ...(cookieEnabled && tenantId && {
        query: `tenantId=${tenantId}`,
      }),
    });

    middleware(socket);

    socket
      .on("*", ({ data }) => {
        let [eventName, eventData] = data;

        if (eventName === "notification") {
          translateActivityProps(eventData);
          store.dispatch(receiveNotification(eventData));
        }

        if (eventData && eventData.args) {
          eventData = eventData.args;
        }

        // TODO handle eventName === 'unauthorized' correctly,
        // show message that something wrong with authorization
        const actionType = `SOCKET_EVENT_${eventName
          .replace(/\./g, "_")
          .toUpperCase()}`;
        store.dispatch({ type: actionType, data: eventData });
      })
      .on("connect", () => {
        if (!cookieEnabled) {
          authWSConnection();
        }
      })
      .on("accessToken", (accessToken) => {
        if (cookieEnabled) {
          setWSAccessToken(accessToken);
          authWSConnection();
        }
      })
      .on("reauthenticate", authWSConnection)
      .on("unauthorized", (data) => {
        if (data.message === "SESSION_TIMEOUT") {
          authWSConnection();
        } else {
          socket.close();
        }
      });

    resolve(socket);
  });
}
