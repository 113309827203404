export const palette = {
  navigationSidebar: {
    background: "linear-gradient(white, white)",
    fontColor: "#394463",
    fontSelected: "#266FE2",
    hoverActive: "#F2F7FF",
    icon: "#546491",
    titleColor: "#546491",
  },
};
