import { heartbeat } from "tools/cp-middleware-adapter";
import { useInterval } from "react-use";
import { isCheckpointDomain } from "data/isCheckpointDomain";

export const useHeartbeat = () => {
  const sessionId = window.sessionStorage.getItem("sessionId");

  useInterval(() => {
    if (isCheckpointDomain) {
      heartbeat({
        sessionId,
      });
    }
  }, 20000);
};
