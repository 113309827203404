import * as actions from "./notificationHidePutActions.js";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.NOTIFICATION_HIDE_PUT_REQUEST]: (state, action) => ({
    ...state,
    [action.id]: {
      loading: true,
      loaded: false,
      error: null,
      data: state.data,
    },
  }),

  [actions.NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    [action.id]: {
      loading: false,
      loaded: true,
      error: null,
      data: action.data,
    },
  }),

  [actions.NOTIFICATION_HIDE_PUT_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    [action.id]: {
      loading: false,
      loaded: false,
      error: action.error,
      data: null,
    },
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function notificationsHideReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
