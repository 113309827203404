import { call, put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./notificationsTouchAllPutActions.js";

function* notificationsTouchAllPut(action) {
  try {
    let response = yield authorizedApi.put(`notifications/touch-all`).json();
    yield put(actions.requestSuccess(response.data, response.unreadCount));
    if (action.resolve) {
      yield call(action.resolve, response.data);
    }
  } catch (e) {
    yield put(actions.requestFailure(e));
    if (action.reject) {
      yield call(action.reject, e);
    }
  }
}

function* saga() {
  yield takeEvery(
    actions.NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST,
    notificationsTouchAllPut
  );
}

export default saga;
