// ------------------------------------
// Constants
// ------------------------------------
export const MEMBERS_ALL_GET_REQUEST = "MEMBERS_ALL_GET_REQUEST";
export const MEMBERS_ALL_GET_REQUEST_SUCCESS =
  "MEMBERS_ALL_GET_REQUEST_SUCCESS";
export const MEMBERS_ALL_GET_REQUEST_FAILURE =
  "MEMBERS_ALL_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(payload) {
  return {
    type: MEMBERS_ALL_GET_REQUEST,
    payload,
  };
}

export function requestSuccess(data, payload) {
  return {
    type: MEMBERS_ALL_GET_REQUEST_SUCCESS,
    data,
    payload,
  };
}

export function requestFailure(error, payload) {
  return {
    type: MEMBERS_ALL_GET_REQUEST_FAILURE,
    error,
    payload,
  };
}
