export function createBroadcastChannel() {
  let prefix = "_BROADCAST_";
  let re = new RegExp("^" + prefix);
  let random = Math.random();
  let id = 0;

  function get(key) {
    return localStorage.getItem(prefix + key);
  }

  function set(key, value) {
    // storage event is not fired when value is set first time
    if (id === 0) {
      localStorage.setItem(prefix + key, random);
    }
    localStorage.setItem(prefix + key, value);
  }

  function remove(key) {
    localStorage.removeItem(prefix + key);
  }

  function toJSON(input) {
    // save random value in storage to fix issue in IE that storage event
    // is fired on same page where setItem was called
    let obj = [id++, random];
    if (input !== undefined) {
      obj.push(input);
    }
    return JSON.stringify(obj);
  }

  let callbacks = {};
  let index = 0;
  let channel;

  if (typeof window.BroadcastChannel === "function") {
    channel = new window.BroadcastChannel(prefix);
    channel.addEventListener("message", function (event) {
      if (event.target.name === prefix) {
        let key = event.data?.name;
        for (let fn of callbacks[key] || []) {
          fn(event.data.data, key);
        }
      }
    });
  } else {
    window.addEventListener(
      "storage",
      function (e) {
        // prevent event to be executed on remove in IE
        if (re.test(e.key) && index++ % 2 === 0) {
          let key = e.key.replace(re, "");
          if (callbacks[key]) {
            let value = e.newValue || get(key);
            if (value && value !== random) {
              let obj = JSON.parse(value);
              if (obj && obj[1] !== random) {
                for (let fn of callbacks[key]) {
                  fn(obj[2], key);
                }
              }
            }
          }
        }
      },
      false
    );
  }

  return {
    broadcast(event, message) {
      if (channel) {
        channel.postMessage({ name: event, data: message });
      } else {
        set(event, toJSON(message));

        queueMicrotask(function () {
          remove(event);
        });
      }
    },
    on(event, fn) {
      if (!callbacks[event]) {
        callbacks[event] = [];
      }
      callbacks[event].push(fn);
    },
  };
}
