import { Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Loading } from "@perimeter_81/ui";
import CheckTenant from "components/auth/CheckTenant/CheckTenant";
import { ErrorBoundary } from "components/common/ErrorBoundary/ErrorBoundary";
import * as paths from "./paths";
import * as urlGenerators from "./urlGenerators";
import {
  useDocumentTitle,
  useI18nInit,
  useRequest,
  useScript,
  useWhitelabel,
} from "hooks";

import {
  automaticLicenseFlow,
  cpDemoTenantIds,
  createKnowledgeBaseLinks,
} from "data/constants";
import { lazyWithRetry } from "tools/lazyWithRetry";
import { env } from "env";
import { bookDemo, CheckpointRouter } from "./checkpoint";
import type { AppProps } from "types";
import { Roles } from "types";
import { isCheckpointDomain } from "data/isCheckpointDomain";
import { partnerCheck, SubscriptionStatus } from "tools/cp-middleware-adapter";
import { useDispatch, useSelector } from "react-redux";
import {
  requestFailure,
  setUserData,
} from "store/api/cpCheckTenantGet/cpCheckTenantGetActions";
import { getLogoutReason } from "tools/localStorage";
import usePollSubscriptionStatus from "./hooks/usePollSubscriptionStatus";

let Authorized = lazyWithRetry(
  () => import("components/auth/Authorized/Authorized")
);

let AppSwitch = lazyWithRetry(() => import("./AppSwitch"));

let Auth0Lock = lazyWithRetry(
  () => import(`components/auth/Auth0Lock/Auth0Lock`)
);
let ResetPassword = lazyWithRetry(() => import("./routes/ResetPassword"));
let Invitation = lazyWithRetry(() => import("./routes/Invitation"));

let SWSSOAuth = lazyWithRetry(
  () => import("components/sonicwall/SWSSOAuth/SWSSOAuth")
);

let WrongWorkspace = lazyWithRetry(() => import("./routes/WrongWorkspace"));
let FindWorkspace = lazyWithRetry(() => import("./routes/FindWorkspace"));
let Blocked = lazyWithRetry(() => import("./routes/Blocked"));

function onIntercomLoad() {
  window.Intercom("boot", { app_id: "rgt4wcyq" });
}

function initDelighted() {
  if (!window.delighted) {
    window.delighted = [];
  }
}

function Authorization(props?: AppProps) {
  const { appName, sessionConfig } = props;
  let { name, general } = useWhitelabel();
  let isI18NReady = useI18nInit({ name, general });
  createKnowledgeBaseLinks(name, general.knowledgeBaseUrl);
  useDocumentTitle();
  const location = useLocation();
  const dispatch = useDispatch();
  const [{ loading: loadingPartnerCheck }, sendPartnerCheckReq] = useRequest(
    partnerCheck
  );
  const { userData } = useSelector((s: any) => s.cpCheckTenantGet);

  const isAppAdmin = (roles: { name: string }[]) => {
    return roles.some((role) => Roles.ADMIN === role.name);
  };

  const isCPReadOnly = useMemo(() => {
    if (isCheckpointDomain) {
      const userRole = sessionConfig?.user?.roles;
      const lowerCaseAppName = appName.toLocaleLowerCase();
      if (userRole?.application[lowerCaseAppName]) {
        return !isAppAdmin(userRole.application[lowerCaseAppName]);
      }
      const isGlobalAdmin = userRole?.global.some((role) =>
        [Roles.ADMIN, Roles.PRIMARY_ADMIN].includes(role.name)
      );
      return !isGlobalAdmin;
    }
    return false;
  }, [appName, sessionConfig?.user?.roles]);

  const isCpDemoTenant = cpDemoTenantIds.includes(
    sessionConfig?.user?.activeTenant
  );

  useEffect(() => {
    if (isCheckpointDomain && (!isCPReadOnly || isCpDemoTenant)) {
      sendPartnerCheckReq()
        .then(({ data }) => {
          dispatch(setUserData(data));
        })
        .catch((err) => {
          dispatch(requestFailure(err.message));
        });
    }
  }, [dispatch, isCPReadOnly, isCpDemoTenant, sendPartnerCheckReq]);

  const subscriptionStatus = usePollSubscriptionStatus();

  useScript("https://widget.intercom.io/widget/rgt4wcyq", {
    preventLoad: name !== "perimeter81" && location.pathname.includes(bookDemo),
    onLoad: onIntercomLoad,
    onCleanup: () => window.Intercom("shutdown"),
  });

  useScript(
    `https://www.googletagmanager.com/gtm.js?id=${env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
    {
      preventLoad: name !== "perimeter81",
    }
  );

  useScript(
    `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/${env.REACT_APP_DELIGHTED_ID}/delighted.js`,
    {
      beforeLoad: initDelighted,
      preventLoad: name !== "sonicwall",
    }
  );

  if (!isI18NReady || loadingPartnerCheck) {
    return <Loading />;
  }
  function showCPPages() {
    const {
      tenantExists,
      userExists,
      noSKUMode,
      discoveryEnabled,
      chargebeeLicenseExists,
      checkpointLicenseExists,
      checkpointLicenseErrors,
    } = userData || {};

    if (!isCheckpointDomain) {
      return false;
    }

    if (chargebeeLicenseExists && userExists) {
      return false;
    }

    if (
      tenantExists &&
      userExists &&
      noSKUMode &&
      discoveryEnabled &&
      (!checkpointLicenseExists || !chargebeeLicenseExists)
    ) {
      return false
    }

    if (
      !tenantExists ||
      !userExists ||
      !checkpointLicenseExists ||
      !chargebeeLicenseExists ||
      (isCPReadOnly && !isCpDemoTenant)
    ) {
      return true;
    }

    if (automaticLicenseFlow) {
      const subscriptionStatusPendingOrFail =
        subscriptionStatus.status !== SubscriptionStatus.SUCCESS;
      return (
        (!chargebeeLicenseExists && subscriptionStatusPendingOrFail) ||
        checkpointLicenseErrors !== undefined
      );
    }

    return false;
  }

  if (showCPPages()) {
    return (
      <CheckpointRouter
        appProps={props}
        isCPReadOnly={isCPReadOnly}
        isCpDemoTenant={isCpDemoTenant}
        subscriptionStatus={subscriptionStatus.status}
      />
    );
  }

  return (
    <ErrorBoundary>
      <Switch>
        {name !== "perimeter81" && [
          <Route path={paths.BLOCKED}>
            <Suspense fallback={<Loading />}>
              <Blocked />
            </Suspense>
          </Route>,

          <Route path={paths.WRONG_WORKSPACE}>
            <Suspense fallback={<Loading />}>
              <WrongWorkspace
                findWorkspaceUrl={urlGenerators.findWorkspaceUrl}
              />
            </Suspense>
          </Route>,

          <Route path={paths.FIND_WORKSPACE}>
            <Suspense fallback={<Loading />}>
              <FindWorkspace />
            </Suspense>
          </Route>,
        ]}

        <CheckTenant wrongWorkspaceUrl={urlGenerators.wrongWorkspaceUrl}>
          <Switch>
            {!isCheckpointDomain && (
              <Route
                path={paths.SIGN_IN}
                render={(props) => {
                  let token = new URLSearchParams(props.location.search).get(
                    "authtoken"
                  );
                  if (token) {
                    return (
                      <SWSSOAuth
                        {...props}
                        token={token}
                        signInUrl={urlGenerators.signInUrl}
                        rootUrl={urlGenerators.rootUrl}
                      />
                    );
                  }
                  let reason = getLogoutReason();
                  if (reason) {
                    props.history.replace(props.location, { reason });
                    return null;
                  }
                  return (
                    <Suspense fallback={<Loading />}>
                      <Auth0Lock
                        {...props}
                        rootUrl={urlGenerators.rootUrl}
                        signInUrl={urlGenerators.signInUrl}
                        resetPasswordUrl={urlGenerators.resetPasswordUrl}
                      />
                    </Suspense>
                  );
                }}
              />
            )}

            <Route path={paths.RESET_PASSWORD}>
              <Suspense fallback={<Loading />}>
                <ResetPassword />
              </Suspense>
            </Route>
            <Route path={paths.INVITATION}>
              <Suspense fallback={<Loading />}>
                <Invitation />
              </Suspense>
            </Route>

            <Route
              path={paths.ROOT}
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Authorized signInUrlGenerator={urlGenerators.signInUrl}>
                    <AppSwitch />
                  </Authorized>
                </Suspense>
              )}
            />

            <Redirect to={urlGenerators.rootUrl()} />
          </Switch>
        </CheckTenant>
      </Switch>
    </ErrorBoundary>
  );
}

export default Authorization;
