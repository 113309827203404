import { call, put, takeEvery } from "redux-saga/effects";
import requestWithSubTenantIfNeeded from "../requestWithSubTenantIfNeeded";
import * as actions from "./subscriptionActivateAddonsPostActions";

function* subscriptionActivateAddonsPost(action) {
  try {
    let response = yield call(
      requestWithSubTenantIfNeeded,
      "post",
      "billing-gateway/subscription/activateAddons",
      { json: action.payload }
    );

    yield put(actions.requestSuccess(response.data));

    if (action.resolve) {
      yield call(action.resolve, response.data);
    }
  } catch (e) {
    yield put(actions.requestFailure(e));

    if (action.reject) {
      yield call(action.reject, e);
    }
  }
}

export default function* () {
  yield takeEvery(
    actions.SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST,
    subscriptionActivateAddonsPost
  );
}
