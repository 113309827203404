import React, { createContext, useContext, useState } from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import type { AppProps } from "types";

interface Props {
  children: ReactNode;
  appProps: AppProps;
}

interface ContextData {
  appProps: AppProps;
  workspaceName: string;
  setWorkspaceName: Dispatch<SetStateAction<string>>;
}

const Context = createContext<ContextData | undefined>(undefined);

export const CheckpointProvider = (props: Props) => {
  const { children, appProps } = props;
  const [workspaceName, setWorkspaceName] = useState("");

  return (
    <Context.Provider value={{ appProps, workspaceName, setWorkspaceName }}>
      {children}
    </Context.Provider>
  );
};

export const useCheckpointContext = () => useContext(Context);
