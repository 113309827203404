import { call, put, takeEvery } from "redux-saga/effects";
import requestWithSubTenantIfNeeded from "../requestWithSubTenantIfNeeded";
import * as actions from "./getStartedProgressGetActions";
import { BaseRequestAction } from "types";

export function request() {
  return requestWithSubTenantIfNeeded(
    "get",
    "onboarding/get-started"
  );
}

function* getStartedProgressGet(action: BaseRequestAction) {
  const { resolve, reject } = action;
  try {
    const { data } = yield call(request);
    yield put(actions.requestSuccess(data));

    if (resolve) {
      yield call(resolve, data);
    }
  } catch (error) {
    yield put(actions.requestFailure(error));

    if (reject) {
      yield call(reject, error);
    }
  }
}

function* saga() {
  yield takeEvery(actions.GET_STARTED_PROGRESS_GET_REQUEST, getStartedProgressGet);
}

export default saga;
