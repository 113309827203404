import { useEffect } from "react";
import detectBrowser from "safervpn-storybook/src/tools/detectBrowser";
import { useSelector } from "react-redux";

export function useIntercom(props) {
  const hide = !!props?.hide;
  let { usersMeGet, rootAccountGet } = useSelector((s) => ({
    usersMeGet: s.usersMeGet,
    rootAccountGet: s.rootAccountGet,
  }));

  useEffect(() => {
    let ic = window.Intercom;
    if (usersMeGet.data && rootAccountGet.data && ic) {
      let {
        id,
        email,
        firstName = "",
        lastName = "",
        tenantId,
        role,
        createdAt,
        intercomHash,
      } = usersMeGet.data.user;
      let { planId, subscriptionStatus } = rootAccountGet.data.account;
      let intercomSettings = {
        app_id: "rgt4wcyq",
        user_id: id,
        name: `${firstName} ${lastName}`,
        created_at: createdAt,
        user_hash: intercomHash,
        email,
        tenant: tenantId,
        role: role.name,
        OS: detectBrowser?.os,
        plan: planId,
        plan_status: subscriptionStatus,
        hide_default_launcher: hide,
      };

      ic("reattach_activator");
      ic("update", intercomSettings);
    }
  }, [usersMeGet.data, rootAccountGet.data, hide]);
}
