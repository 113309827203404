const checkCpDomain = () => {
  const hostname = window.location.hostname;
  return hostname.includes("portal.checkpoint");
};
export const isCheckpointDomain = checkCpDomain();

export const checkpoint = "checkpoint";

export const isCheckpointProduction =
  window.location.hostname === "portal.checkpoint.com";
