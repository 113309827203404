// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

enum Extension {
  CSV = "csv",
  PDF = "pdf",
  TXT = "txt",
}

enum Mime {
  CSV = "text/csv",
  PDF = "application/pdf",
}

export { Extension, Mime };
