import {
  newTracker,
  trackSelfDescribingEvent,
} from "@snowplow/browser-tracker";
import { hasFeatureFlag, ffKeys } from "../../featureFlag";
import { AnalyticsEvents } from "./AnalyticsEvents";
import { Event, Payload, UserInfo } from "./analyticsTypes";

type EventData = Payload &
  UserInfo & {
    client_tstamp: string;
    url: string;
    browser_resolution: string;
  };

export default class SnowplowAnalytics extends AnalyticsEvents {
  tracker = null;

  init = () => {
    if (hasFeatureFlag(ffKeys.USER_PROFILE_SNOWPLOW)) {
      this.tracker = newTracker("sp", "sp-collector-lb.perimeter81.com", {});
    }
  };

  extractEventData = (payload: Payload): EventData => {
    return {
      ...this.config,
      ...payload,
      client_tstamp: new Date().toUTCString(),
      url: document.URL,
      browser_resolution: `${window.innerWidth}x${window.innerHeight}`,
    };
  };

  track = (payload: Payload, event?: Event) => {
    if (!this.tracker) return;
    super.track(payload, event);
  };

  sendTrackEvent = (data: EventData) => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:perimeter81/workspace/jsonschema/1-0-0",
        data,
      },
    });
  };
}
