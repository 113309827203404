import * as actions from "./whitelabelGetActions";

// ------------------------------------
// Action Handlers
// ------------------------------------
let ACTION_HANDLERS = {
  [actions.WHITELABEL_GET_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
  }),
  [actions.WHITELABEL_GET_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
  }),
  [actions.WHITELABEL_GET_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function whitelabelGetReducer(state = initialState, action) {
  let handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
