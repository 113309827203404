import {
  Action,
  BaseRequestAction,
  BaseRequestSuccessAction,
  BaseRequestFailureAction,
} from "types";
import * as actions from "./getStartedProgressGetActions";
import { State, Data } from "./types";

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [actions.SET_STARTED_PROGRESS]: (
    state: State,
    action: BaseRequestSuccessAction<Data>
  ): State => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: { ...state.data, ...action.data },
  }),
  [actions.GET_STARTED_PROGRESS_GET_REQUEST]: (
    state: State,
    action: BaseRequestAction
  ): State => ({
    ...state,
    loading: true,
    loaded: !!state.data,
    error: null,
    data: state.data,
  }),
  [actions.GET_STARTED_PROGRESS_GET_REQUEST_SUCCESS]: (
    state: State,
    action: BaseRequestSuccessAction<Data>
  ): State => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
  }),
  [actions.GET_STARTED_PROGRESS_GET_REQUEST_FAILURE]: (
    state: State,
    action: BaseRequestFailureAction
  ): State => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function getStartedProgressGetReducer(
  state: State = initialState,
  action: Action
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
