import { makeStyles } from "@mui/styles";
import { links } from "data/constants";

const headerHeight = document.querySelector("header")?.clientHeight || 0;

const createClasses = makeStyles(() => ({
  bookDemo: {
    background: "white",
    border: 0,
    width: "100vw",
    height: `calc(100vh - ${headerHeight}px)`,
    display: "flex",
    justifyContent: "center",
  },
}));

function BookDemo() {
  const classes = createClasses();
  return (
    <iframe
      title="Book a Demo"
      className={classes.bookDemo}
      src={links.book_cp_demo}
    />
  );
}

export default BookDemo;
