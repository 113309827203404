import * as actions from "./notificationsGetActions";

/*
import { NOTIFICATION_TOUCH_REQUEST_SUCCESS } from 'actions/notificationTouchActions';
*/

import {
  NOTIFICATION_TOUCH_PUT_REQUEST_SUCCESS,
  NOTIFICATION_TOUCH_PUT_REQUEST,
} from "../notificationTouchPut/notificationTouchPutActions";
import {
  NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS,
  NOTIFICATION_HIDE_PUT_REQUEST,
} from "../notificationHidePut/notificationHidePutActions";

// ------------------------------------
// Action Handlers
// ------------------------------------

const handleNotificationTouch = (state, action) => ({
  ...state,
  notifications: state.notifications.map((n) =>
    n._id === action.id ? { ...n, readBy: [...n.readBy, action.userId] } : n
  ),
  unreadCount: action.unreadCount ?? state.unreadCount - 1,
});

const handleNotificationHide = (state, action) => ({
  ...state,
  unreadCount: action.unreadCount || state.unreadCount,
  notifications: state.notifications.filter((n) => n._id !== action.id),
});

const ACTION_HANDLERS = {
  [actions.NOTIFICATIONS_GET_REQUEST]: (state, action) => ({
    ...state,
    ...{
      loading: true,
      loaded: false,
      error: null,
      notifications: action.sinceId ? state.notifications : null,
      noMoreNotifications: action.sinceId ? state.noMoreNotifications : false,
      lastId: action.sinceId ? state.lastId : null,
    },
  }),

  [actions.NOTIFICATIONS_GET_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    ...{
      loading: false,
      loaded: true,
      error: null,
      notifications: state.notifications
        ? state.notifications.concat(action.notifications)
        : action.notifications,
      lastId: action.lastId,
      noMoreNotifications: action.page === action.totalPages,
      unreadCount: action.unreadCount,
    },
  }),

  [actions.NOTIFICATIONS_GET_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    ...{
      loading: false,
      loaded: false,
      error: action.error,
    },
  }),

  [actions.NOTIFICATIONS_RECEIVE_NOTIFICATION]: (state, action) => ({
    ...state,
    notifications: [action.notification, ...(state.notifications || [])],
    unreadCount: state.unreadCount + 1,
  }),

  /*


  [NOTIFICATION_TOUCH_REQUEST_SUCCESS]: (state, action) =>
    Object.assign({}, state, {
      unreadCount: action.unreadCount
    }),

    */

  // Apply changes ahead of server response
  [NOTIFICATION_HIDE_PUT_REQUEST]: handleNotificationHide,

  [NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS]: handleNotificationHide,

  [NOTIFICATION_TOUCH_PUT_REQUEST]: handleNotificationTouch,

  [NOTIFICATION_TOUCH_PUT_REQUEST_SUCCESS]: handleNotificationTouch,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  notifications: null,
  noMoreNotifications: false,
  lastId: null,
  unreadCount: 0,
};

export default function notificationsGetReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
