import React from "react";
import { createCommonClasses } from "../common/styles";
import { createClasses } from "./styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { links } from "../../../data/constants";

export const ActivatingAccount = () => {
  const commonClasses = createCommonClasses();
  const classes = createClasses();
  const { t } = useTranslation("checkpoint");

  return (
    <div className={commonClasses.container}>
      <img
        height="120px"
        width="123px"
        src={`${links.imagesBaseUrl}/icons/Harmony_loader.gif`}
        alt="harmony_loader"
      />
      <Typography variant="h2" component="h1" className={classes.title}>
        {t("ACTIVATION_LOADER.TITLE")}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        className={commonClasses.subtitle}
      >
        {t("ACTIVATION_LOADER.SUBTITLE")}
      </Typography>
    </div>
  );
};
