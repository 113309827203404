import { openModal, closeModal, Button } from "@perimeter_81/ui";

export function getUserConfirmation(message, cb) {
  let parsed = message;
  try {
    parsed = JSON.parse(message);
  } catch (error) {
    parsed = {
      title: "",
      description: message,
      cancelBtn: "Cancel",
      okBtn: "Ok",
    };
  }

  let { title, description, cancelBtn, okBtn } = parsed;

  openModal({
    dialogTitleProps: {
      children: title,
    },
    dialogContentProps: {
      children: description,
    },
    dialogActionsProps: {
      children: (
        <>
          <Button
            secondary
            onClick={() => {
              window.dispatchEvent(new CustomEvent("ReactRouterPromptCancel"));
              cb(false);
              closeModal();
            }}
          >
            {cancelBtn}
          </Button>
          <Button
            primary
            onClick={() => {
              window.dispatchEvent(new CustomEvent("ReactRouterPromptConfirm"));
              cb(true);
              closeModal();
            }}
          >
            {okBtn}
          </Button>
        </>
      ),
    },
  });
}
