// ------------------------------------
// Constants
// ------------------------------------
export const CHECK_TENANT_GET_REQUEST = "CHECK_TENANT_GET_REQUEST";
export const CHECK_TENANT_GET_REQUEST_SUCCESS =
  "CHECK_TENANT_GET_REQUEST_SUCCESS";
export const CHECK_TENANT_GET_REQUEST_FAILURE =
  "CHECK_TENANT_GET_REQUEST_FAILURE";
export const CHECK_TENANT_GET_RESET = "CHECK_TENANT_GET_RESET";
export const CHECK_TENANT_GET_SET_TENANT_ID = "CHECK_TENANT_GET_SET_TENANT_ID";

// ------------------------------------
// Actions
// ------------------------------------
export function request(email, code, tenantId) {
  return {
    type: CHECK_TENANT_GET_REQUEST,
    email,
    code,
    tenantId,
  };
}

export function requestSuccess(res, tenantId) {
  return {
    type: CHECK_TENANT_GET_REQUEST_SUCCESS,
    data: res,
    tenantId,
  };
}

export function requestFailure(error, tenantId) {
  return {
    type: CHECK_TENANT_GET_REQUEST_FAILURE,
    error,
    tenantId,
  };
}

export function reset() {
  return {
    type: CHECK_TENANT_GET_RESET,
  };
}

export function setTenantId(tenantId) {
  return {
    type: CHECK_TENANT_GET_SET_TENANT_ID,
    tenantId,
  };
}
