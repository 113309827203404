import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { request as getStartedProgressGet } from "store/api/organization/getStartedProgressGet/getStartedProgressGetActions";

const TEN_SECONDS = 10000;
let interval;

export const useGetStartedProgressPoll = (progress: number) => {
  const dispatch = useDispatch();
  const finished = progress === 100;

  useEffect(() => {
    if (interval && finished) {
      clearInterval(interval);
    }

    if (!finished) {
      clearInterval(interval);
      interval = setInterval(() => {
        dispatch(getStartedProgressGet());
      }, TEN_SECONDS);
    }

    return () => {
      clearInterval(interval);
    };
  }, [finished, dispatch]);

  return interval;
};
