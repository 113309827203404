// ------------------------------------
// Constants
// ------------------------------------
export const PROFILE_PUT_REQUEST = "PROFILE_PUT_REQUEST";
export const PROFILE_PUT_REQUEST_SUCCESS = "PROFILE_PUT_REQUEST_SUCCESS";
export const PROFILE_PUT_REQUEST_FAILURE = "PROFILE_PUT_REQUEST_FAILURE";
export const PROFILE_PUT_RESET = "PROFILE_PUT_RESET";

// ------------------------------------
// Actions
// ------------------------------------
export function request(payload, resolve, reject) {
  return {
    type: PROFILE_PUT_REQUEST,
    payload,
    resolve,
    reject,
  };
}

export function requestSuccess(res) {
  return {
    type: PROFILE_PUT_REQUEST_SUCCESS,
    data: res,
  };
}

export function requestFailure(error) {
  return {
    type: PROFILE_PUT_REQUEST_FAILURE,
    error,
  };
}

export function reset() {
  return {
    type: PROFILE_PUT_RESET,
  };
}
