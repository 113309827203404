export function noop() {}

export function identity(a) {
  return a;
}

export function compose(...fns) {
  return fns.reduce((a, b) => (...args) => a(b(...args)));
}

export function once(fn, cooldown) {
  let first = true;
  let result;
  return function () {
    if (first) {
      first = false;
      result = fn();
      if (cooldown) {
        setTimeout(() => {
          first = true;
        }, cooldown);
      }
    }
    return result;
  };
}

function areInputsEqual(newInputs, lastInputs) {
  if (newInputs.length !== lastInputs.length) {
    return false;
  }
  for (let i = 0; i < newInputs.length; i++) {
    if (newInputs[i] !== lastInputs[i]) {
      return false;
    }
  }
  return true;
}

export function memoize(resultFn, isEqual = areInputsEqual) {
  let lastThis;
  let lastArgs = [];
  let lastResult;
  let calledOnce = false;
  return function memoized(...newArgs) {
    if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
      return lastResult;
    }
    lastResult = resultFn.apply(this, newArgs);
    calledOnce = true;
    lastThis = this;
    lastArgs = newArgs;
    return lastResult;
  };
}
