import {
  createInjectSagasStore,
  sagaMiddleware,
  reloadSaga,
} from "redux-sagas-injector";
import { compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootSaga from "./rootSaga";
import rootReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = [applyMiddleware(thunk, sagaMiddleware)];

const initialState = {};

const store = createInjectSagasStore(
  { rootSaga },
  rootReducer,
  initialState,
  composeEnhancers(...enhancers)
);

export default store;

// Hot reload sagas (requires Webpack or Browserify HMR to be enabled)
if (module.hot) {
  module.hot.accept("./rootSaga", () => {
    reloadSaga("rootSaga", require("./rootSaga").default);
  });
}
