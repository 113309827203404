// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_SOURCES_GET_REQUEST = "PAYMENT_SOURCES_GET_REQUEST";
export const PAYMENT_SOURCES_GET_REQUEST_SUCCESS =
  "PAYMENT_SOURCES_GET_REQUEST_SUCCESS";
export const PAYMENT_SOURCES_GET_REQUEST_FAILURE =
  "PAYMENT_SOURCES_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(resolve, reject) {
  return {
    type: PAYMENT_SOURCES_GET_REQUEST,
    resolve,
    reject,
  };
}

export function requestSuccess(data) {
  return {
    type: PAYMENT_SOURCES_GET_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: PAYMENT_SOURCES_GET_REQUEST_FAILURE,
    error,
  };
}
