// Partial type of the application props provided by Infinity Portal
export interface AppProps {
  // Name of the application inside CPIP
  appName: string;
  sessionConfig: {
    user: {
      id: string;
      name: string;
      firstName: string;
      lastName: string;
      email: string;
      activeTenant: string;
      role: string;
      tenants: {
        id: string;
        name: string;
        region: string;
        geo: {
          region: string;
          url: string;
        };
      }[];
      roles: {
        global: {
          name: Roles;
        }[];
        application:
          | {
              [key: string]: [
                {
                  name: Roles;
                }
              ];
            }
          | {};
      };
    };
  };
}

export enum Roles {
  PRIMARY_ADMIN = "primary-administrator",
  ADMIN = "admin",
  READ_ONLY = "readonly",
  USER_ADMIN = "user-admin",
}
