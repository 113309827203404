import * as actions from "./applicationConnectPostActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.APPLICATION_CONNECT_POST_REQUEST]: (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    data: null,
  }),

  [actions.APPLICATION_CONNECT_POST_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
  }),

  [actions.APPLICATION_CONNECT_POST_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),

  [CURRENT_CHILD_ORGANIZATION_SET]: () => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function networksPostReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
