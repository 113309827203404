export let resources = {
  ACCOUNT: "account",
  LICENSE: "license",
  LICENSE_ISSUER: "licenseIssuer",
  LICENSE_ACCESS_TOKEN: "licenseAccessToken",
  INVITE: "invite",
  DEPLOYMENT: "deployment",
  GROUP: "group",
  INSTALLATION: "installation",
  NOTIFICATION: "notification",
  ACTIVITY: "activity",
  USER: "user",
  REGION: "region",
  FILE: "file",
  SESSION: "session",
  ID_PROVIDER: "idProvider",
  FREE_TRIAL: "freeTrial",
  ROLE: "role",
  ORGANIZATION_ROLE: "organization.role",
  WEBSOCKET: "websocket",
  ADMIN: "admin",
  ORGANIZATION: "organization",
  BILLING: "billing",
  BILLING_HIDE_INFO: "billing.hideInfo",
  LICENSE_HANDOVER: "licenseHandover",
  APP_SETTINGS: "appsettings",
  PROFILE: "profile",
  MEMBER: "member",
  SSL_CERTIFICATE: "sslCertificate",
  APPLICATION: "application",
  POLICY: "policy",
  INTEGRATION: "integration",
  ADDRESS: "address",
  SERVICE: "service",
  DPC: "dpc",
  NETWORK_POLICY: "networkPolicy",
  MANUAL_KEYS: "manualKeys",
  CUSTOMURL: "customUrl",
  SWG: "swg",
  ADDON: "addon",
  IDPS: "addon.idp",
  ALWAYS_ON_VPN: "addon.vpnAlwaysOn",
  SHARED_GATEWAYS: "addon.sharedGateways",
  EXTENDED_LOG: "addon.extendedLog",
  DNS_FILTERING: "addon.dnsFiltering",
  SIEM: "addon.SIEM",
  NETWORK_CONTROLLER: "addon.networkController",
  NETWORK_POLICY_ADDON: "addon.networkPolicy",
  DPC_ADDON: "addon.dpc",
  SUPPORT_ACCESS: "supportAccess",
  MANUAL_KEYS_ADDON: "addon.manualKeys",
  MONITOR_DASHBOARD: "monitoringDashboard",
  EXTERNAL_API_ADDON: "addon.apiKey",
  EXTERNAL_API: "apiKey",
  SCIM_ADDON: "addon.scim",
  SCIM: "scim",
  ACTIVE_SESSIONS: "activeSessionsLogs",
  SWG_ADDON: "addon.swg",
  DISABLE_SIGN_OUT_ADDON: "addon.disableSignOut",
  DEMO_ADMIN_ADDON: "addon.adminDemo",
  CONNECTWISE: "connectWise",
  DEVICE_INVENTORY: "deviceInventory",
  ASSIGN_CUSTOMER_ADMIN: "assign.customer.admin",
  INSTALLATION_KEYS: 'installationKeys',
  GET_STARTED: 'getStarted',
};

let addonPermissionNames = [
  "addon.idp",
  "addon.vpnAlwaysOn",
  "addon.sharedGateways",
  "addon.extendedLog",
  "addon.dnsFiltering",
  "addon.SIEM",
  "addon.networkController",
  "addon.networkPolicy",
  "addon.dpc",
  "addon.manualKeys",
  "addon.apiKey",
  "addon.scim",
  "addon.swg",
  "addon.disableSignOut",
];

export const access = {
  WRITE: "write",
  READ: "read",
};

let userPermissions = null;
let addonPermissions = null;

// see src/store/api/usersMeGet/usersMeGetSaga.js
export function setUserPermissions(permissions) {
  userPermissions = new Map(
    permissions?.reduce((result, p) => {
      if (!addonPermissionNames.includes(p)) {
        result.push([p]);
      }
      return result;
    }, [])
  );
}

export function setAddonPermissions(permissions) {
  addonPermissions = new Map(permissions.map((p) => [p]));
  window.dispatchEvent(
    new CustomEvent("addonsUpdate", {
      detail: addonPermissions,
    })
  );
}

export function hasAccess(resource, accessLevel) {
  let permission = accessLevel ? `${resource}:${accessLevel}` : resource;
  return addonPermissions?.has(permission) || userPermissions?.has(permission);
}

export function hasAccessToOneOf(...arr) {
  return arr.some(([resource, accessLevel]) =>
    hasAccess(resource, accessLevel)
  );
}

export function hasAccessToAll(...arr) {
  return arr.every(([resource, accessLevel]) =>
    hasAccess(resource, accessLevel)
  );
}
