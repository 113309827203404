function padString(str) {
  return str + "===".slice((str.length + 3) % 4);
}

function unescape(str, withPaddings) {
  if (withPaddings) {
    str = padString(str);
  }
  return str.replace(/-/g, "+").replace(/_/g, "/");
}

function escape(str) {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export function baseEncode(str, options = { encodeURI: true }) {
  return escape(btoa(options.encodeURI ? encodeURIComponent(str) : str));
}

export function baseDecode(str, options = { decodeURI: true }) {
  const result = atob(unescape(str));
  return options.decodeURI ? decodeURIComponent(result) : result;
}

export function encode(obj, options) {
  return baseEncode(JSON.stringify(obj), options);
}

export function decode(str, options) {
  try {
    return JSON.parse(baseDecode(str, options));
  } catch (e) {
    return {};
  }
}
