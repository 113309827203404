import { Redirect } from "react-router-dom";
import {
  accessFailed,
  activatingAccount,
  activatingLicense,
  bookDemo,
  choosePassword,
  createWorkspace,
  noReadOnly,
  welcomePage,
} from "./index";
import { useSelector } from "react-redux";
import paths from "routes/organization/organizationPaths";
import { ROOT } from "routes/paths";
import { SubscriptionStatus } from "../../tools/cp-middleware-adapter";
import {
  automaticLicenseFlow,
  checkpointLicenseExpiredCode,
  checkpointLicenseInvalidCode,
} from "data/constants";

export interface Props {
  isCPReadOnly: boolean;
  isCpDemoTenant: boolean;
  subscriptionStatus: SubscriptionStatus;
}

export const RedirectByTenantState = (props: Props) => {
  const { isCPReadOnly, isCpDemoTenant, subscriptionStatus } = props;

  const { userData } = useSelector((s: any) => s.cpCheckTenantGet);
  const {
    tenantExists,
    userExists,
    chargebeeLicenseExists,
    checkpointLicenseExists,
    checkpointLicenseErrors,
    manuallyActivatingLicense,
    noSKUMode,
    discoveryEnabled,
  } = userData || {};

  const dashboardPath = paths(ROOT).DASHBOARD;
  const cpReadOnlyAndNotDemoTenant = !isCpDemoTenant && isCPReadOnly;
  const noData = !userData;
  const noTenant = !tenantExists;
  const noTenantAndNoCpLicense = noTenant && !checkpointLicenseExists;
  const tenantWithoutChargebeeLicense = tenantExists && !chargebeeLicenseExists;
  const tenantWithoutUser = tenantExists && !userExists;
  const userWithoutCPLicenses = !checkpointLicenseExists && userExists;
  const showWelcomePage =
    noSKUMode && !discoveryEnabled && userWithoutCPLicenses;
  const allowDiscovery = noSKUMode && discoveryEnabled && userWithoutCPLicenses;

  if (cpReadOnlyAndNotDemoTenant) {
    return <Redirect to={noReadOnly} />;
  }

  if (
    noData ||
    (automaticLicenseFlow &&
      (checkpointLicenseErrors === checkpointLicenseExpiredCode ||
        subscriptionStatus === SubscriptionStatus.FAIL))
  ) {
    return <Redirect to={accessFailed} />;
  }

  if (noTenantAndNoCpLicense && !noSKUMode) {
    return <Redirect to={bookDemo} />;
  }

  if (noTenant) {
    return <Redirect to={createWorkspace} />;
  }

  if (tenantWithoutUser) {
    return <Redirect to={choosePassword} />;
  }

  if (showWelcomePage) {
    return <Redirect to={welcomePage} />;
  }

  if (allowDiscovery) {
    return <Redirect to={dashboardPath} />;
  }

  if (
    checkpointLicenseErrors === checkpointLicenseInvalidCode ||
    manuallyActivatingLicense
  ) {
    return <Redirect to={activatingLicense} />;
  }

  if (
    tenantWithoutChargebeeLicense ||
    (automaticLicenseFlow &&
      (props.subscriptionStatus === SubscriptionStatus.PENDING ||
        !props.subscriptionStatus))
  ) {
    return (
      <Redirect
        to={automaticLicenseFlow ? activatingAccount : activatingLicense}
      />
    );
  }

  // redirect to forbidden page
  return <Redirect to="/" />;
};
