// ------------------------------------
// Constants
// ------------------------------------
export const SUBSCRIPTION_GET_REQUEST = "SUBSCRIPTION_GET_REQUEST";
export const SUBSCRIPTION_GET_REQUEST_SUCCESS =
  "SUBSCRIPTION_GET_REQUEST_SUCCESS";
export const SUBSCRIPTION_GET_REQUEST_FAILURE =
  "SUBSCRIPTION_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(resolve, reject) {
  return {
    type: SUBSCRIPTION_GET_REQUEST,
    resolve,
    reject,
  };
}

export function requestSuccess(data) {
  return {
    type: SUBSCRIPTION_GET_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: SUBSCRIPTION_GET_REQUEST_FAILURE,
    error,
  };
}
