import i18n from "i18next";
import Fetch from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import { validator } from "safervpn-storybook/src/tools/validator";
import { capitalize, concatWith } from "tools/string";
import { env } from "env";
// import Cache from 'i18next-localstorage-cache';
// import LanguageDetector from 'i18next-browser-languagedetector';

export let DEFAULT_LANGUAGE = "en";
export let LNG = "lng";

let i18nInstance = null;

export function getI18nInstance() {
  if (!i18nInstance) {
    throw new Error(
      "i18n instance most be created before getI18nInstance call"
    );
  }

  return i18nInstance;
}

// returns current language
export function getLanguage() {
  if (window.localStorage) {
    return window.localStorage.getItem(LNG) || DEFAULT_LANGUAGE;
  }

  return DEFAULT_LANGUAGE;
}

// changes language. Сauses full rerender
export function setLanguage(lng) {
  if (window.localStorage) {
    window.localStorage.setItem(LNG, lng);
  }

  if (i18nInstance) {
    i18nInstance.changeLanguage(lng);
  }
}

let nameToBrand = {
  sonicwall: "SonicWall",
  perimeter81: "Harmony SASE",
};

let errors = {
  sonicwall: {
    ACCOUNT_EXPIRED:
      "Your SonicWall Cloud Edge is expired ... <a href={{supportEmail, link}} target='_blank'>contact support</a>",
  },
  perimeter81: {
    ACCOUNT_EXPIRED:
      "Something went wrong with your Harmony SASE workspace. Please contact your account admin.",
  },
};

const formatters = {
  lowercase(value) {
    return value.toLowerCase();
  },
  uppercase(value) {
    return value.toUpperCase();
  },
  capitalize(value) {
    return capitalize(value);
  },
  capitalizeAll(value) {
    return value.replaceAll(/(\w+)/g, capitalize);
  },
  link(value) {
    if (validator.isEmail(value)) {
      return `mailto:${value}`;
    }
    if (validator.isURL(value)) {
      return value;
    }
    return "";
  },
  withSpace(value) {
    return value ? `${value} ` : value;
  },
  removeUnderscore(value) {
    return value.replace("_", " ");
  },
  with(value, lng, options) {
    const { prefix, postfix } = options;
    return value ? concatWith("", prefix, value, postfix) : "";
  },
  array(value, lng, options) {
    const { nameKey = "name", valueKey = "value" } = options;
    return value
      ? value.map((item) => `${item[nameKey]} = ${item[valueKey]}`)
      : "";
  },
};

function i18nSetup(config) {
  let { whitelabel, supportEmail } = config;
  let brand = nameToBrand[whitelabel];
  let options = {
    backend: {
      loadPath: `${
        process.env.REACT_APP_ENVS_URL || ""
      }/translations/{{lng}}/{{ns}}.json?v=${env.REACT_APP_GIT_TAG || ""}`,
      requestOptions: {
        cache:
          env.REACT_APP_ENVIRONMENT === "development"
            ? "default"
            : "force-cache",
      },
    },

    lng: getLanguage(),

    fallbackLng: "en",
    load: "currentOnly",
    defaultNS: [],
    ns: [
      "activity",
      "breadcrumbs",
      "common",
      "errors",
      "navigation",
      "notifications",
    ],

    react: {
      // useSuspense: false,
      wait: true, // globally set to wait for loaded translations in translate hoc
      // exposeNamespace: true // exposes namespace on data-i18next-options to be used in eg. locize-editor
    },

    // debug:
    //   ['staging', 'development'].indexOf(process.env.REACT_APP_ENVIRONMENT) >
    //   -1,

    // cache: {
    //   enabled: true
    // },

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
      defaultVariables: {
        brand,
        connectorName: brand === "SonicWall" ? "WireGuard" : brand,
        supportEmail,
        linkToSignIn:
          brand === "SonicWall"
            ? "//app.sonicwalledge.com/find-workspace"
            : `//app.${env.REACT_APP_P81_DOMAIN}/sign-in`,
        ...errors[whitelabel],
      },
    },
  };
  i18nInstance = i18n;
  const result = i18n.use(Fetch).use(initReactI18next).init(options);
  Object.entries(formatters).forEach(([name, formatter]) => {
    i18n.services.formatter.add(name, formatter);
  });

  return result;
}

export default i18nSetup;
