// ------------------------------------
// Constants
// ------------------------------------
export const UI_POPUPS_POST_REQUEST = "UI_POPUPS_POST_REQUEST";
export const UI_POPUPS_POST_REQUEST_SUCCESS = "UI_POPUPS_POST_REQUEST_SUCCESS";
export const UI_POPUPS_POST_REQUEST_FAILURE = "UI_POPUPS_POST_REQUEST_FAILURE";
export const UI_POPUPS_POST_RESET = "UI_POPUPS_POST_RESET";

// ------------------------------------
// Actions
// ------------------------------------
export function request(payload, resolve, reject) {
  return {
    type: UI_POPUPS_POST_REQUEST,
    payload,
    resolve,
    reject,
  };
}

export function requestSuccess(res) {
  return {
    type: UI_POPUPS_POST_REQUEST_SUCCESS,
    data: res,
  };
}

export function requestFailure(error) {
  return {
    type: UI_POPUPS_POST_REQUEST_FAILURE,
    error,
  };
}

export function reset() {
  return {
    type: UI_POPUPS_POST_RESET,
  };
}
