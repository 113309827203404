export function capitalize([first, ...rest] = "") {
  return first ? first.toUpperCase() + rest.join("") : "";
}

export function strip(str) {
  return str?.replace(/<[^>]+>/g, "");
}

export function splitList(values) {
  return values
    .split(",")
    .map((v) => v.trim())
    .filter(Boolean);
}

export function trim(str) {
  return str?.trim();
}

export function trimStart(str) {
  return str?.trimStart();
}

export function trimEnd(str) {
  return str?.trimEnd();
}

export function concatWith(separator, ...strings) {
  return strings
    .filter((str) => typeof str === "string" && str)
    .join(separator);
}

export function removeWordFromString(str, wordToRemove) {
  let regex = new RegExp('\\b' + wordToRemove + '\\b', 'g');
  return str.replace(regex, '');
}

export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toLowerCase();
}
