import { analytics } from "tools/analytics";
import { useToggle } from "react-use";

export function useBookDemoPopup(
  eventName: string,
  eventTag: string
): {
  bookDemoOpen: boolean;
  handleTrialRequest: () => void;
} {
  const [bookDemoOpen, toggleBookDemoOpen] = useToggle(false);

  function handleTrialRequest() {
    if (!bookDemoOpen) {
      analytics.get().track({
        event_name: eventName,
        tag: eventTag,
      });
    }

    toggleBookDemoOpen(!bookDemoOpen);
  }

  return {
    bookDemoOpen,
    handleTrialRequest,
  };
}
