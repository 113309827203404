import i18n from "i18next";
import { INFRA_STATUSES } from 'components/activity/ActivityTemplate/constants';
import { concatWith } from "tools/string";

const TUNNEL = "tunnel";
const GATEWAY = "gateway";
const scaleUnitsReduceEvent = 'api.activity.network.scaleUnit.reduce';
const scaleUnitsIncreaseEvent = 'api.activity.network.scaleUnit.increase';

export function translateActivityProps(props) {
  const isScaleUnitsEvent = props.eventName === scaleUnitsReduceEvent || props.eventName === scaleUnitsIncreaseEvent;

  if (props.role && props.role.displayName) {
    props.role.displayName = i18n.t(
      "team:" + props.role.displayName
    );
  }

  if (props.oldRole && props.oldRole.displayName) {
    props.oldRole.displayName = i18n.t(
      "team:" + props.oldRole.displayName
    );
  }

  if (props.user) {
    props.user.displayName =
      props.user.firstName && props.user.lastName
        ? `${props.user.firstName} ${props.user.lastName}`
        : props.user.email;
  }

  if (props.releasedBy) {
    props.releasedBy.displayName =
      props.releasedBy.firstName || props.releasedBy.lastName
        ? concatWith(" ", props.releasedBy.firstName, props.releasedBy.lastName)
        : props.releasedBy.email;
  }

  if (props.unblockedUser) {
    props.unblockedUser.displayName =
      props.unblockedUser.firstName && props.unblockedUser.lastName
        ? `${props.unblockedUser.firstName} ${props.unblockedUser.lastName}`
        : props.unblockedUser.email;
  }

  if (props.regions && props.regions.length) {
    props.regionNames = props.regions.map((x) => x.name).join(", ");
    props.regionNamesWithInstancesCount = props.regions
      .map((x) => `${x.name} (${x.instances ? x.instances.length : 0})`)
      .join(", ");
  }

  if (props.regions?.[0]?.attributes?.runningMode) {
    props.changeStateAction = (props.regions[0].attributes.runningMode.idle ?
      i18n.t('common:DISABLED') : i18n.t('common:ENABLED')).toLowerCase();
  }

  if (props.regions?.[0]?.instances?.[0]?.attributes?.runningMode) {
    props.changeStateAction = (props.regions[0].instances[0].attributes.runningMode.idle ?
      i18n.t('common:DISABLED') : i18n.t('common:ENABLED')).toLowerCase();
  }

  if (isScaleUnitsEvent) {
    const { oldValue, newValue } = JSON.parse(props.fieldChanges)[0];
    props.scaleUnitsDifference =
      props.eventName === scaleUnitsReduceEvent ? oldValue - newValue : newValue - oldValue;
  }

  if (props?.innerMessage) {
    const { TunnelErrorEvent, TunnelChangedStatusEvent, GatewayChangedStatusEvent, ChildTunnelChangedStatusEvent, StrongSwanTunnelError } = props.innerMessage;
    props.level =
      TunnelErrorEvent ||
        TunnelChangedStatusEvent?.tunnelStatus === INFRA_STATUSES.DOWN || GatewayChangedStatusEvent?.gatewayStatus === INFRA_STATUSES.DOWN || ChildTunnelChangedStatusEvent?.tunnelStatus === INFRA_STATUSES.DOWN || StrongSwanTunnelError
        ? 3
        : 1;
    props.infraType = TunnelChangedStatusEvent || ChildTunnelChangedStatusEvent || TunnelErrorEvent || StrongSwanTunnelError ? TUNNEL : GATEWAY;
  }

  return props;
}
