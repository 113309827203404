// ------------------------------------
// Constants
// ------------------------------------
// prettier-ignore
export const NOTIFICATION_HIDE_PUT_REQUEST = 'NOTIFICATION_HIDE_PUT_REQUEST';
// prettier-ignore
export const NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS = 'NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS';
// prettier-ignore
export const NOTIFICATION_HIDE_PUT_REQUEST_FAILURE = 'NOTIFICATION_HIDE_PUT_REQUEST_FAILURE';

// ------------------------------------
// Actions
// ------------------------------------
export function request(id) {
  return {
    type: NOTIFICATION_HIDE_PUT_REQUEST,
    id,
  };
}

export function requestSuccess(res, id, unreadCount) {
  return {
    type: NOTIFICATION_HIDE_PUT_REQUEST_SUCCESS,
    data: res,
    id,
    unreadCount,
  };
}

export function requestFailure(error, id) {
  return {
    type: NOTIFICATION_HIDE_PUT_REQUEST_FAILURE,
    error,
    id,
  };
}
