import { useState, useEffect } from "react";

let cachedScripts = new Set();

export function useScript(src, options = {}) {
  let { id, beforeLoad, onLoad, onError, preventLoad, onCleanup } = options;
  let [state, setState] = useState({ loaded: false, error: false });
  useEffect(() => {
    if (preventLoad) return;
    if (cachedScripts.has(src)) {
      setState({ loaded: true, error: false });
    } else {
      if (beforeLoad) {
        beforeLoad();
      }
      cachedScripts.add(src);
      let script = document.createElement("script");
      script.src = src;
      script.async = true;

      if (id) {
        script.id = id;
      }

      function onScriptLoad() {
        setState({ loaded: true, error: false });
        if (typeof onLoad === "function") {
          onLoad();
        }
      }

      function onScriptError() {
        script.remove();
        cachedScripts.delete(src);
        setState({ loaded: false, error: true });
        if (typeof onError === "function") {
          onError();
        }
      }

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      document.body.appendChild(script);

      return () => {
        onCleanup?.();
        script.remove();
      };
    }
  }, [src, id, beforeLoad, onLoad, onError, preventLoad]);
  return [state.loaded, state.error];
}
