import { request } from "store/api/paymentSourcesGet/paymentSourcesGetActions";
import { useResource } from "./useResource";
import { hasAccess, resources, access } from "tools/permissions";
import { useWhitelabel } from "./useWhitelabel";

export function usePaymentSources() {
  let { billing } = useWhitelabel();
  return useResource(
    "paymentSourcesGet",
    request,
    billing.enable && hasAccess(resources.BILLING, access.READ)
  );
}
