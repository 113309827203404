// ------------------------------------
// Constants
// ------------------------------------
export const AGENTS_BUILDS_GET_REQUEST = "AGENTS_BUILDS_GET_REQUEST";
export const AGENTS_BUILDS_GET_REQUEST_SUCCESS =
  "AGENTS_BUILDS_GET_REQUEST_SUCCESS";
export const AGENTS_BUILDS_GET_REQUEST_FAILURE =
  "AGENTS_BUILDS_GET_REQUEST_FAILURE";
export const AGENTS_BUILDS_GET_RESET = "AGENTS_BUILDS_GET_RESET";
// ------------------------------------
// Actions
// ------------------------------------
export function request() {
  return {
    type: AGENTS_BUILDS_GET_REQUEST,
  };
}

export function requestSuccess(data) {
  return {
    type: AGENTS_BUILDS_GET_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: AGENTS_BUILDS_GET_REQUEST_FAILURE,
    error,
  };
}

export function reset() {
  return {
    type: AGENTS_BUILDS_GET_RESET,
  };
}
