import { useDispatch, useSelector } from "react-redux";
import { request as profilePutRequest } from "store/api/profilePut/profilePutActions";
import { request as usersMeGetRequest } from "store/api/usersMeGet/usersMeGetActions";
import type { DrawerStates } from "@perimeter_81/ui";

export function useClassicMode(): {
  isClassicMode: boolean;
  toggleClassicMode: (isClassicMode: boolean) => Promise<void>;
  disableSizeChanges: boolean;
  initLeftState: DrawerStates;
  showDrawerButton: boolean;
} {
  const { isClassicMode, firstName, lastName } = useSelector((s: any) => ({
    isClassicMode: s.usersMeGet.data?.user?.isClassicMode as boolean,
    firstName: s.usersMeGet.data?.user?.firstName as string,
    lastName: s.usersMeGet.data?.user?.lastName as string,
  }));

  const dispatch = useDispatch();

  async function toggleClassicMode(isClassicMode: boolean) {
    await new Promise((resolve, reject) => {
      dispatch(
        profilePutRequest(
          {
            isClassicMode,
            firstName,
            lastName,
          },
          resolve,
          reject
        )
      );
    });
    dispatch(usersMeGetRequest());
  }

  return {
    isClassicMode,
    toggleClassicMode,
    disableSizeChanges: false,
    initLeftState: "closed" as DrawerStates,
    showDrawerButton: true,
  };
}
