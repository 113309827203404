import isEqual from "lodash/isEqual";
import * as actions from "./membersAllGetActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";
import { MEMBER_DELETE_REQUEST_SUCCESS } from "store/api/organization/memberDelete/memberDeleteActions";

// ------------------------------------
// Selectors
// ------------------------------------

// TODO: params argument should always be in sync with request params that being sent

export function membersAllSelector(store, params = {}) {
  let { payload } = store.membersAllGet;
  if (payload && isEqual(payload, params)) {
    return store.membersAllGet;
  }
  return { ...store.membersAllGet, data: null };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.MEMBERS_ALL_GET_REQUEST]: (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
  }),
  [actions.MEMBERS_ALL_GET_REQUEST_SUCCESS]: (state, action) => ({
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
    payload: action.payload,
  }),
  [actions.MEMBERS_ALL_GET_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
  [MEMBER_DELETE_REQUEST_SUCCESS]: (state, action) => {
    if (state.data) {
      let { memberId } = action.payload;
      return {
        ...state,
        data: state.data.filter((member) => member.id !== memberId),
      };
    }
    return state;
  },
  [CURRENT_CHILD_ORGANIZATION_SET]: () => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
  payload: null,
};

export default function membersAllGetReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
