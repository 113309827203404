import activityTypes from "data/activity-types";

export function findType(id) {
  for (let i = 0; i < activityTypes.length; i++) {
    for (let j = 0; j < activityTypes[i].children.length; j++) {
      if (activityTypes[i].children[j].id === id) {
        return activityTypes[i].children[j];
      }
    }
  }

  return null;
}
