import {
  BaseRequestActionCreator,
  BaseRequestSuccessActionCreator,
  BaseRequestFailureActionCreator,
} from "types";

import { Data } from "./types";
// ------------------------------------
// Constants
// ------------------------------------
export const GET_STARTED_PROGRESS_GET_REQUEST = "GET_STARTED_PROGRESS_GET_REQUEST";
export const SET_STARTED_PROGRESS = "SET_STARTED_PROGRESS";
export const GET_STARTED_PROGRESS_GET_REQUEST_SUCCESS =
  "GET_STARTED_PROGRESS_GET_REQUEST_SUCCESS";
export const GET_STARTED_PROGRESS_GET_REQUEST_FAILURE =
  "GET_STARTED_PROGRESS_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export const setProgress: BaseRequestSuccessActionCreator<Data> = (data) => {
  return {
    type: SET_STARTED_PROGRESS,
    data,
  };
};

export const request: BaseRequestActionCreator = (resolve, reject) => {
  return {
    type: GET_STARTED_PROGRESS_GET_REQUEST,
    resolve,
    reject,
  };
};

export const requestSuccess: BaseRequestSuccessActionCreator<Data> = (data) => {
  return {
    type: GET_STARTED_PROGRESS_GET_REQUEST_SUCCESS,
    data,
  };
};

export const requestFailure: BaseRequestFailureActionCreator = (error) => {
  return {
    type: GET_STARTED_PROGRESS_GET_REQUEST_FAILURE,
    error,
  };
};
