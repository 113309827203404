import * as actions from "./checkTenantGetActions";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.CHECK_TENANT_GET_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
  }),
  [actions.CHECK_TENANT_GET_REQUEST_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
  }),
  [actions.CHECK_TENANT_GET_REQUEST_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
  [actions.CHECK_TENANT_GET_SET_TENANT_ID]: (state, action) => ({
    ...state,
    data: {
      tenantId: action.tenantId,
    },
  }),
  [actions.CHECK_TENANT_GET_RESET]: (state, action) => ({ ...initialState }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function checkTenantGetReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
