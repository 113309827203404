import { put, takeEvery, select } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./notificationTouchPutActions";
import { showErrorMessage } from "tools/apiErrorsHelper";

function* notificationTouchPut(action) {
  try {
    let response = yield authorizedApi
      .put(`notifications/${action.id}/touch`)
      .json();
    let userId = yield select((state) => state.usersMeGet.data.user.objectId);

    yield put(
      actions.requestSuccess(
        response.data,
        action.id,
        response.unreadCount,
        userId
      )
    );
  } catch (e) {
    showErrorMessage(e);
    yield put(actions.requestFailure(e, action.id));
  }
}

function* saga() {
  yield takeEvery(actions.NOTIFICATION_TOUCH_PUT_REQUEST, notificationTouchPut);
}

export default saga;
