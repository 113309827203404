import detectBrowser from "safervpn-storybook/src/tools/detectBrowser";
import { env } from "../../../env";
import { UserInfo, ActionInfo, Payload, Event } from "./analyticsTypes";

export abstract class AnalyticsEvents {
  protected config: UserInfo;
  private defaultConfig: ActionInfo = {
    workspace_id: null,
    user_id: null,
    user_type: null,
    env: env.REACT_APP_ENVIRONMENT,
    action: "Click Button",
    sub_properties: {},
    app: "Workspace",
    event_source: "client",
    browser: detectBrowser.name,
    browser_version: detectBrowser.version,
    os: detectBrowser.os,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  abstract sendTrackEvent(event: Object): void;
  abstract extractEventData(payload: Payload): Object;

  configure(config: UserInfo) {
    this.config = {
      ...this.defaultConfig,
      ...config,
    };
  }

  init() {}

  track(payload: Payload, event?: Event) {
    if (event) {
      let { type, key, target, currentTarget } = event;

      if (type === "keydown") {
        payload.label = key;
        return;
      }
      if (type === "click") {
        const _target = currentTarget || target;
        if (!payload.label) {
          payload.label = _target.textContent;
        }
        const className = _target.className;
        if (typeof className === "string") {
          if (className.includes("Popup_closeButton")) {
            payload.label = "x";
          } else if (className.includes("Popup_overlay")) {
            payload.label = "";
          }
        }
      }
    }
    const data = this.extractEventData(payload);
    this.sendTrackEvent(data);
  }
}
