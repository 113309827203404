import { request } from "store/api/organization/subscriptionGet/subscriptionGetActions";
import { useResource } from "./useResource";
import { hasAccess, resources, access } from "tools/permissions";

export function useSubscription() {
  return useResource(
    "subscriptionGet",
    request,
    hasAccess(resources.BILLING, access.READ)
  );
}
