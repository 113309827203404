import { lazy } from "react";

const storageKey = "reloaded";

export function lazyWithRetry(importComponent) {
  return lazy(async () => {
    const pageHasBeenReloaded = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );

    try {
      const component = await importComponent();

      if (pageHasBeenReloaded) {
        window.sessionStorage.removeItem(storageKey);
      }

      return component;
    } catch (error) {
      if (!pageHasBeenReloaded) {
        window.sessionStorage.setItem(storageKey, "true");
        return window.location.reload();
      }

      throw error;
    }
  });
}
