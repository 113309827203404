import { useEffect } from "react";

export function useMetaDescription(description) {
  useEffect(() => {
    let meta = document.createElement("meta");
    meta.name = "description";
    meta.content = description;
    document.head.appendChild(meta);
  }, [description]);
}
