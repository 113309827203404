import {
  ActionTypes,
  RequestFailureActionCreator,
  SetUserActionCreator,
} from "./types";

export const requestFailure: RequestFailureActionCreator = (error) => {
  return {
    type: ActionTypes.CP_CHECK_TENANT_GET_REQUEST_FAILURE,
    error,
  };
};

export const setUserData: SetUserActionCreator = (userData) => {
  return {
    type: ActionTypes.CP_CHECK_TENANT_GET_SET_USER_DATA,
    userData,
  };
};
