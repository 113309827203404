import * as actions from "./subscriptionActivateAddonsPostActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST]: () => ({
    loading: true,
    loaded: false,
    error: null,
    data: null,
  }),
  [actions.SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_SUCCESS]: (
    state,
    action
  ) => ({
    loading: false,
    loaded: true,
    error: null,
    data: action.data,
  }),
  [actions.SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_FAILURE]: (
    state,
    action
  ) => ({
    loading: false,
    loaded: false,
    error: action.error,
    data: null,
  }),
  [CURRENT_CHILD_ORGANIZATION_SET]: () => initialState,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
};

export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
