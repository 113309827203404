let platforms = [
  {
    name: "windows",
    packageType: "exe",
    icon: "windows",
    label: "Windows",
    description: "Windows 10 or later",
    withBadge: true,
  },
  {
    name: "windows",
    packageType: "msi",
    icon: "windows",
    label: "Windows",
    description: "Windows 10 or later",
    withBadge: true,
  },
  {
    name: "macos",
    packageType: "pkg",
    icon: "mac",
    label: "Mac",
    description: "macOS 11 or later",
  },
  {
    name: "linux",
    packageType: "deb",
    icon: "ubuntu",
    label: "Ubuntu",
    description: "Ubuntu 20.04 or later",
    withInstructions: true,
  },
  {
    name: "linux",
    packageType: "rpmRedHat",
    icon: "redHat",
    label: "Red Hat",
    description: "RedHat 7 or later",
    withInstructions: true
  },
  {
    name: "linux",
    packageType: "rpmFedora",
    icon: "fedora",
    label: "Fedora",
    description: "Fedora 30 or later",
    withInstructions: true
  },
  {
    name: "linux",
    packageType: "generic",
    icon: "linux",
    label: "Linux - Other",
    description: "Linux 7.0 or later",
    withInstructions: true
  },
  {
    name: "android",
    packageType: "",
    icon: "android",
    label: "Android / Chromebook",
    description: "Android 8.1.0 or later",
    link:
      "https://play.google.com/store/apps/details?id=com.perimeter81.android",
  },
  {
    name: "ios",
    packageType: "",
    icon: "ios",
    label: "iOS",
    description: "iOS 15 or later",
    link:
      "https://itunes.apple.com/us/app/safervpn-for-business/id1307953381?mt=8",
  },
];

export default platforms;
