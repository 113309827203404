import { useSelector, shallowEqual } from "react-redux";
import { membersAllSelector } from "store/api/organization/membersAllGet/membersAllGetReducer";

export function useMembersAndGroups() {
  return useSelector(
    (s) => ({
      membersAllGet: membersAllSelector(s),
      groupsGet: s.groupsGet,
    }),
    shallowEqual
  );
}
