import { useCallback, useEffect, useState } from "react";
import { isCheckpointDomain } from "../../data/isCheckpointDomain";
import { useInterval } from "react-use";
import {
  checkSubscriptionStatus,
  SubscriptionStatus,
} from "../../tools/cp-middleware-adapter";
import { useRequest } from "../../hooks";
import { automaticLicenseFlow } from "../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../store/api/cpCheckTenantGet/cpCheckTenantGetActions";

const MAX_SUBSCRIPTION_POLLING_RETRIES = 22;
const usePollSubscriptionStatus = () => {
  const [{ loading }, sendSubscriptionStatusReq] = useRequest(
    checkSubscriptionStatus
  );
  const { userData } = useSelector((s: any) => s.cpCheckTenantGet);
  const [subscriptionStatus, setSubscriptionStatus] = useState<{
    status: SubscriptionStatus;
    retries: number;
  }>({ status: undefined, retries: 0 });
  const dispatch = useDispatch();

  const getSubscriptionStatus = useCallback(async () => {
    if (loading) {
      return;
    }

    try {
      const { data } = await sendSubscriptionStatusReq();
      setSubscriptionStatus((state) => ({
        status: data?.status || SubscriptionStatus.FAIL,
        retries: state.retries + 1,
      }));
      if (data.status === SubscriptionStatus.SUCCESS) {
        dispatch(
          setUserData({
            ...userData,
            tenantExists: true,
            userExists: true,
            chargebeeLicenseExists: true,
            checkpointLicenseExists: true,
          })
        );
      }
    } catch (e) {
      setSubscriptionStatus((state) => ({
        ...state,
        status: SubscriptionStatus.FAIL,
      }));
    }
  }, [dispatch, loading, sendSubscriptionStatusReq, userData]);

  useEffect(() => {
    if (subscriptionStatus.retries >= MAX_SUBSCRIPTION_POLLING_RETRIES) {
      setSubscriptionStatus((state) => ({
        ...state,
        status: SubscriptionStatus.FAIL,
      }));
    }
  }, [subscriptionStatus.retries]);

  const statusIsPending =
    subscriptionStatus.status === SubscriptionStatus.PENDING ||
    subscriptionStatus.status === undefined;

  const cpLicenseIsValid = userData?.checkpointLicenseErrors === undefined;
  const chargebeeLicenseMissing = !userData?.chargebeeLicenseExists;

  const shouldPollForSubscriptionStatus =
    userData?.checkpointLicenseExists &&
    statusIsPending &&
    subscriptionStatus.retries < MAX_SUBSCRIPTION_POLLING_RETRIES &&
    cpLicenseIsValid &&
    chargebeeLicenseMissing &&
    userData?.tenantExists &&
    isCheckpointDomain &&
    !userData.manuallyActivatingLicense &&
    automaticLicenseFlow
      ? 1000
      : null;

  useInterval(getSubscriptionStatus, shouldPollForSubscriptionStatus);

  return subscriptionStatus;
};

export default usePollSubscriptionStatus;
