// ------------------------------------
// Constants
// ------------------------------------
// prettier-ignore
export const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST';
// prettier-ignore
export const NOTIFICATIONS_GET_REQUEST_SUCCESS = 'NOTIFICATIONS_GET_REQUEST_SUCCESS';
// prettier-ignore
export const NOTIFICATIONS_GET_REQUEST_FAILURE = 'NOTIFICATIONS_GET_REQUEST_FAILURE';
// prettier-ignore
export const NOTIFICATIONS_RECEIVE_NOTIFICATION = 'NOTIFICATIONS_RECEIVE_NOTIFICATION';

// ------------------------------------
// Actions
// ------------------------------------
export function request(sinceId) {
  return {
    type: NOTIFICATIONS_GET_REQUEST,
    sinceId,
  };
}

export function requestSuccess(
  notifications,
  lastId,
  unreadCount,
  page,
  totalPages
) {
  return {
    type: NOTIFICATIONS_GET_REQUEST_SUCCESS,
    notifications,
    lastId,
    unreadCount,
    page,
    totalPages,
  };
}

export function receiveNotification(data) {
  return {
    type: NOTIFICATIONS_RECEIVE_NOTIFICATION,
    notification: data,
  };
}

export function requestFailure(error) {
  return {
    type: NOTIFICATIONS_GET_REQUEST_FAILURE,
    error,
  };
}
