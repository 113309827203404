import { useEffect } from "react";
import { usePrevious } from "hooks";
import { noop } from "tools/function";

let events = ["mousedown", "touchstart"];

let supportsPassiveEvents;

function canUsePassiveEvents() {
  if (supportsPassiveEvents !== undefined) {
    return supportsPassiveEvents;
  }

  let passive = false;
  let options = {
    // eslint-disable-next-line getter-return
    get passive() {
      passive = true;
    },
  };

  window.addEventListener("test", noop, options);
  window.removeEventListener("test", noop, options);
  supportsPassiveEvents = passive;
  return passive;
}

function getOptions(event) {
  if (event !== "touchstart") {
    return;
  }

  if (canUsePassiveEvents()) {
    return { passive: true };
  }
}

export function useOnClickOutside(ref, handler) {
  let handlerRef = usePrevious(handler);

  useEffect(() => {
    if (!handler) {
      return;
    }

    function listener(event) {
      if (
        !ref.current ||
        !handlerRef.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }
      handlerRef.current(event);
    }

    events.forEach((event) => {
      document.addEventListener(event, listener, getOptions(event));
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, listener, getOptions(event));
      });
    };
  }, [handler, handlerRef, ref]);
}
