import { Action } from "types";
import { ActionTypes, SetUserAction, State, UserFailureAction } from "./types";

const ACTION_HANDLERS = {
  [ActionTypes.CP_CHECK_TENANT_GET_REQUEST_FAILURE]: (
    state: State,
    action: UserFailureAction
  ) => ({
    ...state,
    loading: false,
    error: action.error,
    userData: null,
  }),
  [ActionTypes.CP_CHECK_TENANT_GET_SET_USER_DATA]: (
    state: State,
    action: SetUserAction
  ) => ({
    ...state,
    loading: false,
    error: null,
    userData: { ...state.userData, ...action.userData },
  }),
};

const initialState = {
  loading: false,
  error: null,
  userData: null,
};

export default function cpCheckTenantGetReducer(
  state: State = initialState,
  action: Action
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
