import { call, put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import { clientHintExtractor } from "tools/clientHintExtractor";
import * as actions from "./applicationConnectPostActions";

function* applicationConnectPost(action) {
  try {
    const { platformVersion } = yield clientHintExtractor(['platformVersion']);
    let response = yield authorizedApi
      .post("applications/connect", {
        json: {
          cei: action.payload,
        },
        headers: action.payload.emulateFailure
          ? { "x-end-with-fail": "true" }
          : platformVersion ? { "X-Platform-Version": platformVersion } : {},
      })
      .json();

    yield put(actions.requestSuccess(response.data));

    if (action.resolve) {
      yield call(action.resolve, response.data);
    }
  } catch (e) {
    yield put(actions.requestFailure(e));

    if (action.reject) {
      yield call(action.reject, e);
    }
  }
}

function* saga() {
  yield takeEvery(
    actions.APPLICATION_CONNECT_POST_REQUEST,
    applicationConnectPost
  );
}

export default saga;
