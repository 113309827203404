import { useEffect } from "react";

export function useCanonicalLink() {
  useEffect(() => {
    let link = document.createElement("link");
    link.rel = "canonical";
    link.href = window.location.href;
    document.head.appendChild(link);
  }, []);
}
