import { useState } from "react";

export function useMultiStepForm(steps) {
  let [step, setStep] = useState(0);

  function changeStep(delta) {
    setStep((step + steps.length + delta) % steps.length);
  }

  return {
    Component: steps[step],
    prev(n = -1) {
      changeStep(n);
    },
    next(n = 1) {
      changeStep(n);
    },
  };
}
