import { hasFeatureFlag, ffKeys } from "../../featureFlag";
import PlatformAnalytics from "./PlatformAnalytics";
import SnowplowAnalytics from "./SnowplowAnalytics";
import { AnalyticsEvents } from "./AnalyticsEvents";

export default class AnalyticsFactory {
  analyticsEvents: AnalyticsEvents;
  constructor() {
    this.analyticsEvents = this.createAnalyticsInstance();
  }

  private createAnalyticsInstance = (): AnalyticsEvents =>
    hasFeatureFlag(ffKeys.UI_EVENTS_YARKON_FLOW)
      ? new PlatformAnalytics()
      : new SnowplowAnalytics();
}
