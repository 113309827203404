import type { AttentionBoxProps } from "@perimeter_81/ui";
import type { GetStartedProgress as GetStartedProgressSchemaType } from "@p81-common/p81-mongo-validation-schemas";
export type { AttentionBoxProps, WelcomeCardProps } from "@perimeter_81/ui";

export type GetStartedProgress = GetStartedProgressSchemaType & {
  finished?: boolean;
};

export enum ItemTypes {
  WELCOME_CARD = "WelcomeCard",
  ATTENTION_BOX = "AttentionBox"
}

export type GetStartedProfile = {
  name: "getStarted";
  finishButtonText: string;
  finishEventName: string;
  tasks: Task[];
};

export interface ExploreProfile {
  name: "explore";
  congratulations: Congratulations;
  informationBlocks: InformationBlocks;
}

export type DefaultProfile = [GetStartedProfile, ExploreProfile];

export type AttentionBoxColorOptions =
  | "error"
  | "transparent"
  | "primary"
  | "warning";

export interface Task {
  id: string;
  title: string;
  subtitle: string;
  iconUrl: string;
  completedActionText: string;
  markAsCompletedButtonText: string;
  onCompleteEventName: string;
  actionButtons: ActionButtons;
  needHelp: NeedHelp[];
  activityLogsEvents: string[];
  questionText?: string;
  infoCards?: InfoCard[];
  dependsOn?: number;
}

export interface TaskWithState extends Task {
  status: "success" | "pending";
  markedAsComplete: boolean;
  finishButtonText: string;
  onContinue: (event: string) => Promise<void>;
  onFinish: (event: string) => Promise<void>;
}

export interface ActionButtons {
  left?: ActionButton[];
  right?: ActionButton[];
  postComplete?: ActionButton[];
}

export interface ActionButton {
  name: string;
  iconPath?: string;
  link?: string;
  color?: "primary" | "secondary" | "third";
  allowSkip?: boolean;
  disabledText?: string;
  disabled?: boolean;
  eventName: string;
  alwaysOn?: boolean;
  permission?: string;
  onClick?: (resolve?: () => void) => Promise<void>;
}

export interface NeedHelp {
  name: string;
  type: string;
  link?: string;
  videoId?: string;
  eventName: string;
  onClick?: () => void;
}

export interface InfoCard {
  iconPaths: string[];
  title: string;
  type: string;
  link?: string;
  videoId?: string;
  buttonText: string;
  eventName: string;
  onClick?: () => void;
}

export interface Congratulations {
  firstView: FirstView;
  secondView: SecondView;
}

export interface FirstView {
  title: string;
  titleHighlight: string;
  subtitle: string;
  videoUrl: string;
}

export interface SecondView {
  title: string;
  subtitle: string;
  attentionBox: AttentionBox;
}

export interface AttentionBox
  extends Partial<AttentionBoxProps>,
    Partial<Item> {
  title: string;
  titleHighlight: string;
  subtitle: string;
}

export interface InformationBlocks {
  upperLayer: Layer[];
  lowerLayer: Layer[];
}

export interface Layer {
  title: string;
  type: string;
  layout: Layout[];
}

export interface Layout {
  type: string;
  title?: string;
  items: Item[];
  layout?: NestedLayout;
}

export interface NestedLayout {
  type: string;
  items: Item[];
}

export interface Item {
  type: string;
  color?: string;
  imageUrl: string;
  title: string;
  subtitle: string;
  primaryButtonAction: ExploreButtonAction;
  secondaryButtonText?: string;
  secondaryButtonAction?: ExploreButtonAction;
  size?: string;
  primaryButtonText?: string;
  additionalButtonText?: string;
  additionalButtonAction?: ExploreButtonAction;
}

export interface ExploreButtonAction {
  type: string;
  link?: string;
  videoId?: string;
  eventName?: string;
}

export interface AdditionalButtonAction {
  type: string;
  videoId: string;
}
