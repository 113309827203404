import { useEffect } from "react";
import { analytics } from "tools/analytics";

let nameToPayload = {
  members: {
    event_name: "team|members|view",
    tag: "Users",
  },
  groups: {
    event_name: "team|groups|view",
    tag: "User Groups",
  },
  networks: {
    event_name: "networks|view",
    tag: "Networks",
  },
  network_policies: {
    event_name: "networks|npr|view",
    tag: "Networks",
  },
  applications: {
    event_name: "applications|view",
    tag: "Applications",
  },
  application_rules: {
    event_name: "application_policies|view",
    tag: "Application Rules",
  },
  system_activity: {
    event_name: "monitor_logs|system_activity|view",
    tag: "System Activity",
  },
  addresses: {
    event_name: "objects|addresses|view",
    tag: "Addresses",
  },
  services: {
    event_name: "objects|services|view",
    tag: "Services",
  },
  user_profiles: {
    event_name: "devices|user_profiles|view",
    tag: "User Configuration",
  },
  downloads: {
    event_name: "devices|downloads|view",
    tag: "Downloads",
  },
  idps: {
    event_name: "settings|idp|view",
    tag: "IDP",
  },
  certificates: {
    event_name: "settings|certificate_mng|view",
    tag: "Certificates",
  },
  integrations: {
    event_name: "settings|integrations|view",
    tag: "Integrations",
  },

  organizations: {
    event_name: "overview|organizations|view",
    tag: "Partners",
  },
  organizations_invoices: {
    event_name: "overview|invoices|view",
    tag: "Invoices",
  },
  dpc: {
    event_name: "dpc|posture_check|view",
    tag: "DPC",
  },
  "user_activity.dpc": {
    event_name: "user_activity|posture_check|view",
    tag: "DPC",
  },
  support_access: {
    event_name: "help|support_access|view",
    tag: "Help",
  },
  web_filter_rules: {
    event_name: "web_security|web_filter_rules|view",
    tag: "Web Filter Rules",
  },
  bypass_rules: {
    event_name: "web_security|bypass_rules|view",
    tag: "Bypass Rules",
  },
  threat_prevention_rules: {
    event_name: "web_security|threat_prevention_rules|view",
    tag: "Threat Prevention",
  },
  customUrls: {
    event_name: "objects|custom_url|view",
    tag: "Custom URL",
  },
  dashboard: {
    event_name: "dashboard|view",
    tag: "Dashboard",
  },
  welcome_popup: {
    event_name: "Onboarding|welcome_video|view",
    tag: "Onboarding",
  },
  web_activity: {
    event_name: "activities|web_activity|view",
    tag: "Web Activity",
  },
  admin_activity: {
    event_name: "activities|admin_activity|view",
    tag: "Audit Activity",
  },
  users_activity: {
    event_name: "activities|users_activity|view",
    tag: "Users Activity",
  },
  infrastructure_events: {
    event_name: "activities|infrastructure_events|view",
    tag: "Infrastructure Events",
  },
  firewall_events: {
    event_name: "activities|firewall_events|view",
    tag: "Firewall Events",
  },
  malware_protection_activity: {
    event_name: "activities|malware_protection_activity|view",
    tag: "Malware Protection Activity",
  },
  web_security_addon: {
    event_name: "billing|addon|web_security|view",
    tag: "Billing",
  },
  active_sessions: {
    event_name: "monitor_logs|active_sessions|view",
    tag: "Active Sessions",
  },
  deviceInventory: {
    event_name: "devices|device_inventory|view",
    tag: "Devices",
  },
  browserSecurity: {
    event_name: "web_security|browser_security|view",
    tag: "Browser Security",
  },
  getStarted: {
    event_name: "get_started|view",
    tag: "Get Started"
  },
  explore: {
    event_name: "explore|view",
    tag: "Explore"
  }
};
const VIEW_PAGE_ACTION: string = "View Page";

export function useTrackView(page: string) {
  useEffect(() => {
    const payload = { ...nameToPayload[page], action: VIEW_PAGE_ACTION };
    analytics.get().track(payload);
  }, [page]);
}
