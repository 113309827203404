import { useSelector } from "react-redux";
import { hasAccessToAll, resources, access } from "tools/permissions";
import { ffKeys, hasFeatureFlag } from "tools/featureFlag";

export function useGetStartedAccess(): boolean {
  const { account } = useSelector((s: any) => ({
    account: s.accountGet?.data?.account,
  }));

  return (
    !!account?.subscriptionStatus &&
    hasFeatureFlag(ffKeys.GET_STARTED) &&
    hasAccessToAll([resources.ACCOUNT, access.WRITE], [resources.GET_STARTED])
  );
}
