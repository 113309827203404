import { useEffect, useState } from "react";
import i18nConfigure from "tools/i18n";

export function useI18nInit({ name, general }) {
  let [ready, setReady] = useState(false);
  useEffect(() => {
    if (name) {
      i18nConfigure({
        whitelabel: name,
        supportEmail: general.supportEmail,
      }).then(() => {
        setReady(true);
      });
    }
  }, [name, general.supportEmail]);
  return ready;
}
