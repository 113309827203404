import { put, takeEvery } from "redux-saga/effects";
import { api } from "tools/apiConfigurator";
import * as actions from "./checkTenantGetActions";

function* checkTenantGet(action) {
  try {
    let response = yield api.get("tenants/check").json();
    yield put(actions.requestSuccess(response.data, action.tenantId));
  } catch (e) {
    yield put(actions.requestFailure(e, action.tenantId));
  }
}

function* saga() {
  yield takeEvery(actions.CHECK_TENANT_GET_REQUEST, checkTenantGet);
}

export default saga;
