import { useEffect, useState } from "react";
import { copyToClipboard } from "safervpn-storybook/src/tools/string";

export function useCopyToClipboard() {
  let [confirmCopy, setConfirmCopy] = useState(false);

  useEffect(() => {
    if (confirmCopy) {
      let timer = setTimeout(() => {
        setConfirmCopy(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [confirmCopy]);

  function copy(text) {
    copyToClipboard(text);
    setConfirmCopy(true);
  }

  return [copy, confirmCopy];
}
