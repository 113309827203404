import { put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./notificationHidePutActions";
import { showErrorMessage } from "tools/apiErrorsHelper";

function* notificationsHidePut(action) {
  try {
    let response = yield authorizedApi
      .put(`notifications/${action.id}/hide`)
      .json();
    yield put(
      actions.requestSuccess(response.data, action.id, response.unreadCount)
    );
  } catch (e) {
    showErrorMessage(e);
    yield put(actions.requestFailure(e, action.id));
  }
}

function* saga() {
  yield takeEvery(actions.NOTIFICATION_HIDE_PUT_REQUEST, notificationsHidePut);
}

export default saga;
