// ------------------------------------
// Constants
// ------------------------------------
export const CUSTOMER_GET_REQUEST = "CUSTOMER_GET_REQUEST";
export const CUSTOMER_GET_REQUEST_SUCCESS = "CUSTOMER_GET_REQUEST_SUCCESS";
export const CUSTOMER_GET_REQUEST_FAILURE = "CUSTOMER_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(resolve, reject) {
  return {
    type: CUSTOMER_GET_REQUEST,
    resolve,
    reject,
  };
}

export function requestSuccess(data) {
  return {
    type: CUSTOMER_GET_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: CUSTOMER_GET_REQUEST_FAILURE,
    error,
  };
}
