import type { DataGridProps } from "@perimeter_81/ui";
import { pageOptions } from "tools/tableHelpers";
const now = new Date();

const defaultTimeFilter = {
  from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
  to: new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  ),
  field: "eventTime",
  isRequired: true,
  isClearable: false,
};

export enum SortValues {
  ASC = "ASC",
  DESC = "DESC",
}

export const noData = " ";

export const fieldsNames = {
  emails: "emails",
  userName: "userName",
  userEmail: "userEmail",
  eventName: "eventName",
  eventTime: "eventTime",
  address: "address",
  addressCountry: "addressCountry",
  createdAt: "createdAt",
  customDNS: "customDNS",
  customUrl: "customUrl",
  device: "device",
  deviceName: "deviceName",
  fieldChanged: "fieldChanges",
  gateway: "gateway",
  geoPoint: "geoPoint",
  group: "group",
  idpName: "idpName",
  idpType: "idpType",
  internalIp: "internalIp",
  ip: "ip",
  network: "network",
  ipLong: "ipLong",
  objectSnapshot: "objectSnapshot",
  providedBy: "providedBy",
  regions: "regions",
  releasedBy: "releasedBy",
  releasedFrom: "releasedFrom",
  tenantId: "tenantId",
  trustedNetworkName: "trustedNetworkName",
  serviceType: "serviceType",
  sentTo: "sentTo",
  user: "user",
  vpnLocation: "vpnLocation",
  vpnServer: "vpnServer",
  error: "error",
  policy: "policy",
  application: "application",
  installation: "installation",
  innerMessage: "innerMessage",
  gatewayId: "gatewayId",
  region: "region",
  regionId: "regionId",
  eventType: "eventType",
  createdDate: "createdDate",
  eventObject: "eventObject",
  details: "details",
  regionName: "regionName",
  networkName: "networkName",
  tunnelSrcIp: "tunnelSrcIp",
  tunnelName: "tunnelName",
  tunnelPeerIp: "tunnelPeerIp",
  tunnelType: "tunnelType",
  clientProfileName: "clientProfileName",
  DPCProfileName: "DPCProfileName",
  applicationName: "applicationName",
  integrationName: "integrationName",
  amount: "amount",
  service: "service",
  rule: "rule",
  role: "role",
  account: "account",
  oldPlan: "oldPlan",
  newPlan: "newPlan",
  paymentInfo: "paymentInfo",
  swgStatus: "swgStatus",
  apiKey: "apiKey",
  installationKey: "installationKey",
  userProperties: "userProperties",
};

export const defaultFilterData = {
  userId: undefined,
  networkId: undefined,
  eventName: undefined,
};

export const defaultQueryData = {
  fields: [
    fieldsNames.emails,
    fieldsNames.eventName,
    fieldsNames.address,
    fieldsNames.addressCountry,
    fieldsNames.createdAt,
    fieldsNames.customDNS,
    fieldsNames.customUrl,
    fieldsNames.device,
    fieldsNames.deviceName,
    fieldsNames.fieldChanged,
    fieldsNames.gateway,
    fieldsNames.geoPoint,
    fieldsNames.group,
    fieldsNames.idpName,
    fieldsNames.idpType,
    fieldsNames.internalIp,
    fieldsNames.ip,
    fieldsNames.network,
    fieldsNames.ipLong,
    fieldsNames.objectSnapshot,
    fieldsNames.providedBy,
    fieldsNames.regions,
    fieldsNames.releasedBy,
    fieldsNames.releasedFrom,
    fieldsNames.tenantId,
    fieldsNames.trustedNetworkName,
    fieldsNames.serviceType,
    fieldsNames.sentTo,
    fieldsNames.user,
    fieldsNames.vpnLocation,
    fieldsNames.vpnServer,
    fieldsNames.error,
    fieldsNames.policy,
    fieldsNames.application,
    fieldsNames.installation,
    fieldsNames.clientProfileName,
    fieldsNames.DPCProfileName,
    fieldsNames.amount,
    fieldsNames.service,
    fieldsNames.rule,
    fieldsNames.role,
    fieldsNames.account,
    fieldsNames.applicationName,
    fieldsNames.integrationName,
    fieldsNames.oldPlan,
    fieldsNames.newPlan,
    fieldsNames.paymentInfo,
    fieldsNames.clientProfileName,
    fieldsNames.swgStatus,
    fieldsNames.apiKey,
    fieldsNames.installationKey,
    fieldsNames.region,
    fieldsNames.userProperties,
  ],
  page: 1,
  itemsPerPage: pageOptions[0].name,
  limit: 10,
  q: "",
  field: "createdAt",
  dir: "desc",
  valuesFilter: defaultFilterData,
  timeFilter: undefined,
  sort: { field: fieldsNames.createdAt, sortOrder: SortValues.DESC },
  containsFilter: undefined,
  withTerminatedMembers: false,
};

export const defaultAuditLogsQueryData = {
  ...defaultQueryData,
  timeFilter: { ...defaultTimeFilter, field: "createdAt" },
};

export const defaultInfraLogsQueryData: typeof defaultQueryData = {
  fields: [
    fieldsNames.createdAt,
    fieldsNames.tenantId,
    fieldsNames.innerMessage,
    fieldsNames.network,
    fieldsNames.region,
    fieldsNames.gateway,
    fieldsNames.region,
    fieldsNames.eventType,
    fieldsNames.eventTime,
  ],
  page: 1,
  itemsPerPage: pageOptions[0].name,
  limit: 10,
  q: "",
  field: "eventTime",
  dir: "desc",
  valuesFilter: defaultFilterData,
  timeFilter: defaultTimeFilter,
  sort: { field: fieldsNames.eventTime, sortOrder: SortValues.DESC },
  containsFilter: undefined,
  withTerminatedMembers: false,
};

export const fieldsToKeys = {
  userName: fieldsNames.releasedBy,
  createdDate: fieldsNames.createdAt,
  network: fieldsNames.network,
  regionName: fieldsNames.addressCountry,
  region: fieldsNames.region,
  eventTime: fieldsNames.eventTime,
};

export const keysToSortBy = {
  [fieldsNames.releasedBy]: `${[fieldsNames.releasedBy]}.memberName`,
};

export const keysToFields = Object.fromEntries(
  Object.entries(fieldsToKeys).map(([k, v]) => [v, k])
);

export enum INFRA_STATUSES {
  UP = "UP",
  DOWN = "DOWN",
}

export const defaultTableData: DataGridProps["tableData"] = {
  data: [],
  page: 0,
  totalPages: 0,
  totalRecords: 0,
};

export const debounceInterval = 1000;
export const maxAllowedToExport = 10000;
export const minSettingsListItems = 3;
export const SERVER = "server";
