import { put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./usersMeGetActions";
import { setUserPermissions } from "tools/permissions";

function* usersMeGet() {
  try {
    let response = yield authorizedApi.get("users/me").json();
    // initializing permissions helper
    setUserPermissions(response.data.permissions);
    yield put(actions.requestSuccess(response.data));
  } catch (e) {
    yield put(actions.requestFailure(e));
  }
}

function* saga() {
  yield takeEvery(actions.USERS_ME_GET_REQUEST, usersMeGet);
}

export default saga;
