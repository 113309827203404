import { useRef, useReducer, useCallback } from "react";

export const states = {
  loading: "loading",
  success: "success",
  error: "error",
};

function defaultReducer<Data = unknown>(
  state: State<Data>,
  action: Action<Data>
) {
  const actionHandlers = {
    [states.loading](state: State) {
      return { ...state, loading: true };
    },
    [states.success](state: State, { data }: Action) {
      return { ...state, loading: false, data };
    },
    [states.error](state: State, { error }: Action) {
      return { ...state, loading: false, error };
    },
  };
  return actionHandlers[action.type]?.(state, action) || state;
}

const defaultInitialState = {
  loading: false,
  data: null,
  error: null,
};

type Request<Data = unknown> = (...args: unknown[]) => Promise<Data>;

export type State<Data = unknown> = {
  loading: boolean;
  data: Data;
  error: Error;
  args?: unknown[];
};

type Action<Data = unknown> = {
  type: string;
  data?: Data;
  error?: Error;
  args?: unknown[];
};

type Reducer<Data = unknown> = (
  state: State<Data>,
  action: Action<Data>
) => State<Data>;

export function useRequest<Data = unknown>(
  _request: Request<Data>,
  reducer: Reducer = defaultReducer,
  initialState = defaultInitialState
) {
  const request = useRef(_request);
  const [state, dispatch] = useReducer(reducer, initialState);

  const send = useCallback(async (...args) => {
    if (typeof request.current === "function") {
      dispatch({ type: states.loading, args });
      try {
        const data = await request.current(...args);
        dispatch({ type: states.success, data, args });
        return { data };
      } catch (error) {
        dispatch({ type: states.error, error, args });
        return { error };
      }
    }
  }, []);

  return [state, send] as [State<Data>, typeof send];
}
