// ------------------------------------
// Constants
// ------------------------------------
export const SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST =
  "SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST";
export const SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_SUCCESS =
  "SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_SUCCESS";
export const SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_FAILURE =
  "SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(payload, resolve, reject) {
  return {
    type: SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST,
    payload,
    resolve,
    reject,
  };
}

export function requestSuccess(data) {
  return {
    type: SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: SUBSCRIPTION_ACTIVATE_ADDONS_POST_REQUEST_FAILURE,
    error,
  };
}
