import * as actions from "./currentChildOrganizationActions";

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [actions.CURRENT_CHILD_ORGANIZATION_SET]: (state, action) => ({
    subTenantId: action.subTenantId,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  currentSubTenantId: null,
};

export default function currentChildOrganization(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
