import { fork, all } from "redux-saga/effects";

import checkTenantGetSaga from "./api/checkTenantGet/checkTenantGetSaga";
import usersMeGetSaga from "./api/usersMeGet/usersMeGetSaga";
import rootAccountGetSaga from "./api/rootAccountGet/rootAccountGetSaga";
import applicationConnectPostSaga from "./api/applicationConnectPost/applicationConnectPostSaga";
import notificationsGetSaga from "./api/notificationsGet/notificationsGetSaga";
import notificationHidePutSaga from "./api/notificationHidePut/notificationHidePutSaga";
import notificationTouchPutSaga from "./api/notificationTouchPut/notificationTouchPutSaga";
import notificationsTouchAllPutSaga from "./api/notificationsTouchAllPut/notificationsTouchAllPutSaga";
import whitelabelGetSaga from "store/whitelabel/whitelabelGetSaga";
import uiPopupsPostSaga from "store/api/uiPopupsPost/uiPopupsPostSaga";
import accountGetSaga from "store/api/organization/accountGet/accountGetSaga";
import subscriptionActivateAddonsPostSaga from "store/api/organization/subscriptionActivateAddonsPost/subscriptionActivateAddonsPostSaga";
import agentsBuildsGetSaga from "store/api/organization/agentsBuildsGet/agentsBuildsGetSaga";
import getStartedProgressGetSaga from "store/api/organization/getStartedProgressGet/getStartedProgressGetSaga";

export default function* rootSaga() {
  yield all([
    fork(checkTenantGetSaga),
    fork(usersMeGetSaga),
    fork(rootAccountGetSaga),
    fork(getStartedProgressGetSaga),
    fork(applicationConnectPostSaga),
    fork(notificationsGetSaga),
    fork(notificationHidePutSaga),
    fork(notificationTouchPutSaga),
    fork(notificationsTouchAllPutSaga),
    fork(whitelabelGetSaga),
    fork(uiPopupsPostSaga),
    fork(accountGetSaga),
    fork(subscriptionActivateAddonsPostSaga),
    fork(agentsBuildsGetSaga),
  ]);
}
