import { useCallback } from "react";
import type { ReactNode } from "react";
import i18n from "i18next";
import {
  showNotif,
  openModal,
  closeModal,
  Button,
  Link,
} from "@perimeter_81/ui";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@perimeter_81/ui";

import { identity } from "tools/function";
import { useShowNotification } from "tools/notification";

function displayTimeoutPopup() {
  openModal({
    dialogTitleProps: {
      children: i18n.t("errors:TIMEOUT_ERROR"),
    },
    dialogContentProps: {
      children: (
        <DialogContentText>
          {i18n.t("errors:TIMEOUT_ERROR_DESCRIPTION")}
        </DialogContentText>
      ),
    },
    dialogActionsProps: {
      children: (
        <>
          <Button
            color="secondary"
            onClick={() => {
              closeModal();
            }}
          >
            {i18n.t("common:CANCEL")}
          </Button>

          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {i18n.t("common:RELOAD")}
          </Button>
        </>
      ),
    },
  });
}

// shows error notification based on error response from API
const style = { minHeight: "24px" };

export function showBillingErrorMessage(error) {
  showErrorMessage(error, void 0, void 0, identity);
}

export function showErrorMessage(
  error,
  dataFormatter = identity,
  fallback = "errors:ERROR",
  parse = undefined
) {
  let message: ReactNode | string = "ERROR";
  let data;
  let errorData = error.response || error.request;
  if (errorData?.status === 504) {
    return displayTimeoutPopup();
  }

  if (errorData?.message) {
    message = errorData.message;
    data = errorData.data;
  } else if (error.message) {
    message = error.message;
  }

  try {
    data = dataFormatter(data);
    message =
      typeof parse === "function"
        ? parse(message, data)
        : i18n.t([`errors:${message}`, fallback], data);
  } catch {
    message = i18n.t(fallback);
  } finally {
    message = (
      <div
        dangerouslySetInnerHTML={{
          __html: message as string,
        }}
      />
    );
    showNotif(message, {
      ms: 5000,
      alertProps: {
        severity: "error",
        variant: "filled",
        style: style,
      },
    });
  }
}

export const useShowErrorMessage = (options: {
  displayToaster: ReturnType<typeof useShowNotification>["displayToaster"];
}) => {
  const { displayToaster } = options;
  const theme = useTheme();

  return useCallback(
    (error, dataFormatter = (v) => v, fallback = "errors:ERROR_RAW") => {
      let message: string | string[] | ReactNode = "ERROR_RAW";
      let data;
      const errorData = error.response || error.request;

      if (errorData?.status === 504) {
        return displayTimeoutPopup();
      }

      if (errorData?.message) {
        message = errorData.message;
        data = errorData.data;
      } else if (error.message) {
        message = error.message;
      }

      try {
        const { supportEmail } = i18n.options.interpolation.defaultVariables;
        message = i18n.t([`errors:${message}`, fallback], dataFormatter(data));
        if (typeof message == "string" && message.includes(supportEmail)) {
          message = message.split(supportEmail);
          message = (
            <>
              {message[0]}&nbsp;
              <Link
                color={theme.palette.secondary[300]}
                href={`mailto:${supportEmail}`}
                target="_blank"
              >
                {supportEmail}
              </Link>
              &nbsp;{message[1]}
            </>
          );
        }
      } finally {
        displayToaster({ severity: "error", message });
      }
    },
    [displayToaster]
  );
};
