// ------------------------------------
// Constants
// ------------------------------------

export const APPLICATION_CONNECT_POST_REQUEST =
  "APPLICATION_CONNECT_POST_REQUEST";

export const APPLICATION_CONNECT_POST_REQUEST_SUCCESS =
  "APPLICATION_CONNECT_POST_REQUEST_SUCCESS";

export const APPLICATION_CONNECT_POST_REQUEST_FAILURE =
  "APPLICATION_CONNECT_POST_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------

export function request(payload, resolve, reject) {
  return {
    type: APPLICATION_CONNECT_POST_REQUEST,
    payload,
    resolve,
    reject,
  };
}

export function requestSuccess(data) {
  return {
    type: APPLICATION_CONNECT_POST_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: APPLICATION_CONNECT_POST_REQUEST_FAILURE,
    error,
  };
}
