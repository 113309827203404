// ------------------------------------
// Constants
// ------------------------------------
export const CURRENT_CHILD_ORGANIZATION_SET = "CURRENT_CHILD_ORGANIZATION_SET";

// ------------------------------------
// Actions
// ------------------------------------
export function set(subTenantId) {
  return {
    type: CURRENT_CHILD_ORGANIZATION_SET,
    subTenantId,
  };
}
