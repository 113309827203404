type HintsValues = 
'brands' |
'mobile' |
'platform' |
'architecture' |
'bitness' |
'fullVersionList' |
'model' |
'platformVersion' |
'wow64';

interface NavigatorUABrandVersion {
    readonly brand: string;
    readonly version: string;
}

interface UADataValues {
    readonly brands?: NavigatorUABrandVersion[];
    readonly mobile?: boolean;
    readonly platform?: string;
    readonly architecture?: string;
    readonly bitness?: string;
    readonly model?: string;
    readonly platformVersion?: string;
    readonly fullVersionList?: NavigatorUABrandVersion[];
    readonly wow64?: boolean;
}

interface UALowEntropyJSON {
    readonly brands: NavigatorUABrandVersion[];
    readonly mobile: boolean;
    readonly platform: string;
}

interface NavigatorUAData extends UALowEntropyJSON {
    getHighEntropyValues(hints: HintsValues[]): Promise<UADataValues>;
    toJSON(): UALowEntropyJSON;
}

export const clientHintExtractor = async (hintsValues: HintsValues[]): Promise<UADataValues> => {
    try {
        const navUAData = (navigator as Navigator & { userAgentData: NavigatorUAData })?.userAgentData;
        return await navUAData.getHighEntropyValues(hintsValues);
    } catch (_error: any) {
        return {};
    }
};
