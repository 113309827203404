import { ROOT } from "./paths";
import { isCheckpointDomain } from "data/isCheckpointDomain";
import { env } from "env";

export const rootUrl = () => ROOT;

export const signInUrl = (redirectUrl) =>
  `${ROOT}/sign-in${redirectUrl ? `?${redirectUrl}` : ""}`;

export const invitationUrl = () => `${ROOT}/invitation`;

export const invitationSuccessUrl = () => `${ROOT}/invitation/success`;

export const resetPasswordUrl = () => `${ROOT}/password-request`;

export const resetPasswordSentUrl = () => `${ROOT}/password-request/sent`;

export const resetPasswordSelectNewUrl = () =>
  `${ROOT}/password-request/select-new`;

export const resetPasswordSuccessUrl = () => `${ROOT}/password-request/success`;

export const wrongWorkspaceUrl = () => `${ROOT}/wrong-workspace`;
export const findWorkspaceUrl = () => `${ROOT}/find-workspace`;

export const applicationConnectUrl = (encodedId) =>
  isCheckpointDomain
    ? `${env.REACT_APP_CP_BASENAME}/connect/${encodedId}`
    : `${ROOT}/connect/${encodedId}`;
