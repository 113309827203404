import { useState } from "react";

export const useVideoPlayer = () => {
  const [videoId, setVideoId] = useState("");

  const handleCloseVideo = (): void => {
    videoId && setVideoId("");
  };

  return {
    videoOpen: !!videoId,
    handleCloseVideo,
    setVideoId,
    videoId,
  }
};
