import { Action } from "./store";

export type GQSPayload = {
  entityName: string;
  fields: string[];
  page?: number;
  itemsPerPage?: number;
  sort?: {
    field: string;
    sortOrder: SortOrder;
  };
  containsFilter?: ContainsFilter;
  valuesFilter: ValuesFilter;
  dateFilter: DateFilter;
};

export type ContainsFilter = {
  fields?: string[];
  searchString: string;
  matchType?: "caseSensitive" | "caseInsensitive";
};

export enum Operator {
  EQUAL = "equal",
  CONTAIN = "contain",
  NOT_EQUAL = "notEqual",
}

export type FilterValue = string | boolean | null;

export type ValueFilter = {
  operator?: Operator;
  matchType?: MatchCase;
  values: Array<FilterValue>;
};

export type ValuesFilter = Record<string, ValueFilter>;

export type DateRangeFilter = {
  from: string;
  to: string;
};

export type DateFilter = Record<string, DateRangeFilter>;

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum MatchCase {
  caseSensitive = "caseSensitive",
  caseInsensitive = "caseInsensitive",
}

export type GQSQueryResponse<T = Record<string, unknown>> = {
  data: T[];
  itemsPerPage: number;
  itemsTotal: number;
  page: number;
  totalPage: number;
};

export type SocketEventArchiveGenerationAction = Action & {
  data: {
    tenantId: string;
    url: string;
  };
};

export type SocketEventArchiveGenerationErrorAction = Action & {
  tenantId: string;
  error: Error;
};
