import { useEffect } from "react";
import isObject from "lodash/isObject";

export function useScrollTo(...args) {
  let { top = 0, left = 0, behavior } = isObject(args[0])
    ? args[0]
    : { top: args[1], left: args[0] };
  useEffect(() => {
    window.scrollTo({ top, left, behavior });
  }, [top, left, behavior]);
}
