import { makeStyles } from "@mui/styles";
import { Theme } from "@perimeter_81/ui";

export const createCommonClasses = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "360px",
    height: "100%",
    textAlign: "center",
  },

  title: {
    margin: theme.spacing(2, 0),
    color: theme.palette.primary[500],
  },

  subtitle: {
    margin: theme.spacing(0, 0, 3, 0),
    color: theme.palette.primary[500],
  },
}));
