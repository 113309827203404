import { PartnerCheck } from "../../../tools/cp-middleware-adapter";
import {
  BaseRequestFailureAction,
  BaseRequestSuccessAction,
} from "../../../types";

export enum ActionTypes {
  CP_CHECK_TENANT_GET_REQUEST_FAILURE = "CP_CHECK_TENANT_GET_REQUEST_FAILURE",
  CP_CHECK_TENANT_GET_SET_USER_DATA = "CP_CHECK_TENANT_GET_SET_USER_DATA",
}
export interface State {
  loading: boolean;
  error: string | null;
  userData: PartnerCheck;
}

export type UserFailureAction = BaseRequestFailureAction & { error: string };

export type SetUserAction = BaseRequestSuccessAction<PartnerCheck> & {
  userData: PartnerCheck;
};

export type RequestFailureActionCreator = (
  error: string
) => BaseRequestFailureAction<string>;

export type SetUserActionCreator = (
  userData: PartnerCheck
) => BaseRequestSuccessAction<PartnerCheck>;
