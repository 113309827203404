// ------------------------------------
// Constants
// ------------------------------------
export const MEMBER_DELETE_REQUEST = "MEMBER_DELETE_REQUEST";
export const MEMBER_DELETE_REQUEST_SUCCESS = "MEMBER_DELETE_REQUEST_SUCCESS";
export const MEMBER_DELETE_REQUEST_FAILURE = "MEMBER_DELETE_REQUEST_FAILURE";
export const MEMBER_DELETE_CLEANUP_SINGLE_MEMBER =
  "MEMBER_DELETE_CLEANUP_SINGLE_MEMBER";

// ------------------------------------
// Actions
// ------------------------------------
export function request(payload, resolve, reject) {
  return {
    type: MEMBER_DELETE_REQUEST,
    payload,
    resolve,
    reject,
  };
}

export function requestSuccess(data, payload) {
  return {
    type: MEMBER_DELETE_REQUEST_SUCCESS,
    data,
    payload,
  };
}

export function requestFailure(error, payload) {
  return {
    type: MEMBER_DELETE_REQUEST_FAILURE,
    error,
    payload,
  };
}
