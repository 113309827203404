import { put, takeEvery, select, all } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./rootAccountGetActions";
import { setFeatureFlags } from "tools/featureFlag";
import { setAddonPermissions } from "tools/permissions";
import { PROFILE_ACCOUNT_GET_REQUEST_SUCCESS } from "store/api/organization/accountGet/accountGetActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";
import { featureFlagUpdateEventName } from "data";

function* rootAccountGet() {
  try {
    let response = yield authorizedApi.get("account").json();

    yield put(actions.requestSuccess(response.data));
  } catch (e) {
    yield put(actions.requestFailure(e));
  }
}

function* setAddonPermissionsSaga(action) {
  let { permissions, featureFlagKeys } =
    action.type === CURRENT_CHILD_ORGANIZATION_SET
      ? yield select((s) => s.rootAccountGet.data.account)
      : action.data.account;
  setAddonPermissions(permissions);
  setFeatureFlags(featureFlagKeys);
  window.dispatchEvent(new CustomEvent(featureFlagUpdateEventName));
}

function* saga() {
  yield all([
    takeEvery(actions.ROOT_ACCOUNT_GET_REQUEST, rootAccountGet),
    takeEvery(
      [
        actions.ROOT_ACCOUNT_GET_REQUEST_SUCCESS,
        PROFILE_ACCOUNT_GET_REQUEST_SUCCESS,
        CURRENT_CHILD_ORGANIZATION_SET,
      ],
      setAddonPermissionsSaga
    ),
  ]);
}

export default saga;
