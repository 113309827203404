import { Component } from "react";
import { ErrorMessage } from "components/common/ErrorMessage/ErrorMessage";

export class ErrorBoundary extends Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    const { fallback: Fallback = ErrorMessage} = this.props;

    if (error !== null) {
      return <Fallback error={error} />;
    }

    return this.props.children;
  }
}
