import checkTenantGetReducer from "./api/checkTenantGet/checkTenantGetReducer";
import usersMeGetReducer from "./api/usersMeGet/usersMeGetReducer";
import rootAccountGetReducer from "./api/rootAccountGet/rootAccountGetReducer";
import applicationConnectPostReducer from "./api/applicationConnectPost/applicationConnectPostReducer";

import notificationsGetReducer from "./api/notificationsGet/notificationsGetReducer";
import notificationHidePutReducer from "./api/notificationHidePut/notificationHidePutReducer";
import notificationTouchPutReducer from "./api/notificationTouchPut/notificationTouchPutReducer";
import notificationsTouchAllPutReducer from "./api/notificationsTouchAllPut/notificationsTouchAllPutReducer";

import currentChildOrganizationReducer from "./domain/currentChildOrganization/currentChildOrganizationReducer";

import whitelabelGetReducer from "store/whitelabel/whitelabelGetReducer";
import uiPopupsPostReducer from "store/api/uiPopupsPost/uiPopupsPostReducer";
import accountGetReducer from "store/api/organization/accountGet/accountGetReducer";
import subscriptionActivateAddonsPostReducer from "store/api/organization/subscriptionActivateAddonsPost/subscriptionActivateAddonsPostReducer";
import agentsBuildsGetReducer from "store/api/organization/agentsBuildsGet/agentsBuildsGetReducer";
import getStartedProgressGetReducer from "store/api/organization/getStartedProgressGet/getStartedProgressGetReducer";
import cpCheckTenantGetReducer from "./api/cpCheckTenantGet/cpCheckTenantGetReducer";

export default {
  checkTenantGet: checkTenantGetReducer,
  cpCheckTenantGet: cpCheckTenantGetReducer,
  usersMeGet: usersMeGetReducer,
  rootAccountGet: rootAccountGetReducer,
  applicationConnectPost: applicationConnectPostReducer,
  notificationsGet: notificationsGetReducer,
  notificationHidePut: notificationHidePutReducer,
  notificationTouchPut: notificationTouchPutReducer,
  notificationsTouchAllPut: notificationsTouchAllPutReducer,
  currentChildOrganization: currentChildOrganizationReducer,
  whitelabelGet: whitelabelGetReducer,
  uiPopupsPost: uiPopupsPostReducer,
  accountGet: accountGetReducer,
  subscriptionActivateAddonsPost: subscriptionActivateAddonsPostReducer,
  agentsBuildsGet: agentsBuildsGetReducer,
  getStartedProgressGet: getStartedProgressGetReducer,
};
