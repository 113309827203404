import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface BackgroundContextProps {
  backgroundImage: string;
  setBackgroundImage: Dispatch<SetStateAction<string>>;
}

const BackgroundContext = createContext<BackgroundContextProps>({
  backgroundImage: "",
  setBackgroundImage: () => {},
});

const useLayoutBackground = () => {
  const context = useContext(BackgroundContext);
  if (!context) {
    throw new Error(
      "useLayoutBackground must be used within a LayoutBackgroundProvider"
    );
  }
  return context;
};

type LayoutBackgroundProviderProps = {
  children: React.ReactNode;
};

const LayoutBackgroundProvider = (props: LayoutBackgroundProviderProps) => {
  const [backgroundImage, setBackgroundImage] = useState<string>("");

  const value: BackgroundContextProps = {
    backgroundImage,
    setBackgroundImage,
  };

  return (
    <BackgroundContext.Provider value={value}>
      {props.children}
    </BackgroundContext.Provider>
  );
};

export { BackgroundContext, useLayoutBackground, LayoutBackgroundProvider };
