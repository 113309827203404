import { put, takeEvery } from "redux-saga/effects";
import { api } from "tools/apiConfigurator";
import * as actions from "./whitelabelGetActions";

function* whitelabelGet() {
  try {
    let response = yield api.get(`whitelabel`).json();
    yield put(actions.requestSuccess(response.data));
  } catch (e) {
    yield put(actions.requestFailure(e));
  }
}

function* saga() {
  yield takeEvery(actions.WHITELABEL_GET_REQUEST, whitelabelGet);
}

export default saga;
