import { request } from "store/whitelabel/whitelabelGetActions";
import { useResource } from "./useResource";

let initialData = {
  name: undefined,
  general: {
    supportEmail: null,
    knowledgeBaseUrl: null,
  },
  ssoSW: {
    enable: false,
  },
  management: {
    enable: false,
  },
  onBoard: {
    enable: false,
  },
  billing: {
    enable: false,
  },
  support: {
    enable: false,
  },
  splunkIntegration: {
    enable: false,
  },
  azureSentinelIntegration: {
    enable: false,
  },
  amazonS3Integration: {
    enable: false,
  },
  auth2: {
    enable: false,
  },
};

export function useWhitelabel() {
  let resource = useResource("whitelabelGet", request);
  return resource.data?.whitelabel || initialData;
}
