import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { BaseRequestAction, Reject, Resolve } from "types";
import { useRequest } from "./useRequest";

export function useDispatchRequest<
  T extends (
    params: unknown,
    resolve: Resolve,
    reject: Reject
  ) => BaseRequestAction
>(requestAction: T) {
  const dispatch = useDispatch();
  const request = useCallback(
    (params) =>
      new Promise((resolve, reject) => {
        dispatch(requestAction(params, resolve, reject));
      }),
    []
  );
  return useRequest(request);
}
