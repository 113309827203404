import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

let pendingQueries = new Set();

export function useResource(key, fn, hasPermission = true) {
  let state = useSelector((s) => s[key] || {});
  let { loading, loaded, error } = state;
  let dispatch = useDispatch();
  useEffect(() => {
    if (!hasPermission) return;
    if (!loaded && !loading && !error && !pendingQueries.has(key)) {
      dispatch(fn());
      pendingQueries.add(key);
    }
    if (loaded) {
      pendingQueries.delete(key);
    }
  }, [dispatch, key, fn, hasPermission, loading, loaded, error]);
  return state;
}
