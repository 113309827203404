import { useEffect } from "react";
import { useWhitelabel } from "hooks";
import { isCheckpointDomain } from "../data/isCheckpointDomain";

let nameToTitle = {
  sonicwall: "SonicWall Cloud Edge",
  perimeter81: "Harmony SASE",
  checkPoint: "Check Point Infinity Portal",
};

export function useDocumentTitle(title) {
  if (isCheckpointDomain) {
    document.title = nameToTitle.checkPoint;
  }
  let { name } = useWhitelabel();
  useEffect(() => {
    if (!isCheckpointDomain) {
      let main = nameToTitle[name];
      if (main) {
        document.title = `${main}${title ? ` - ${title}` : ""}`;
      }
    }
  }, [title, name]);
}
