import {
  requestWithSubtoken,
  currentSubTenantSelector,
} from "tools/apiConfigurator";

export { currentSubTenantSelector };

export function currentTenantSelector(state) {
  return (
    state.rootAccountGet.data && state.rootAccountGet.data.account.tenantId
  );
}

export default async function requestWithSubTenantIfNeeded(method, ...args) {
  return requestWithSubtoken[method](...args).json();
}
