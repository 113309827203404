// ------------------------------------
// Constants
// ------------------------------------
// prettier-ignore
export const NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST = 'NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST';
// prettier-ignore
export const NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_SUCCESS = 'NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_SUCCESS';
// prettier-ignore
export const NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_FAILURE = 'NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_FAILURE';

// ------------------------------------
// Actions
// ------------------------------------
export function request(resolve, reject) {
  return {
    type: NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST,
    resolve,
    reject,
  };
}

export function requestSuccess(res, unreadCount) {
  return {
    type: NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_SUCCESS,
    data: res,
    unreadCount,
  };
}

export function requestFailure(error) {
  return {
    type: NOTIFICATIONS_TOUCH_ALL_PUT_REQUEST_FAILURE,
    error,
  };
}
