import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { encode, decode } from "tools/base64";
import { hasValues } from "tools/object";
import { DEFAULT_QUERY } from "data/constants";

function applyForEachEntry(obj, fn) {
  return Object.entries(obj).reduce((map, [key, value]) => {
    map[key] = fn(key, value);
    return map;
  }, {});
}

function makeQuery(filters, format) {
  let query = filters;
  if (format && typeof format === "function") {
    query = applyForEachEntry(query, format);
  }
  return encode(query);
}

function parseQuery(search, parse) {
  let filter = decode(search);
  if (parse && typeof parse === "function") {
    filter = applyForEachEntry(filter, parse);
  }
  return filter;
}

export function useQueryHelper(
  defaultParams = DEFAULT_QUERY,
  { parse, format } = {}
) {
  let location = useLocation();
  let search = location.search.slice(1);
  let filters = useMemo(
    () => ({
      ...defaultParams,
      ...parseQuery(search, parse),
    }),
    [defaultParams, search, parse]
  );

  let modifyQuery = useCallback(
    (filter) => makeQuery({ ...filters, ...filter }, format),
    [filters, format]
  );

  return {
    location,
    filters,
    hasFilter: () => hasValues(filters),
    getQuery: () => makeQuery(filters, format),
    modifyQuery,
  };
}

export function useQuery(request, defaultParams, options) {
  let { isExact } = useRouteMatch();
  let queryHelper = useQueryHelper(defaultParams, options);
  let {
    filters,
    location: { state },
  } = queryHelper;
  let prevFilters = useRef();

  useEffect(() => {
    if (
      isExact &&
      (!isEqual(prevFilters.current, filters) || state?.forceUpdate)
    ) {
      prevFilters.current = filters;
      request(filters);
    }
  }, [filters, request, isExact, prevFilters, state?.forceUpdate]);

  return queryHelper;
}
