import { useState, useCallback, useMemo, useRef } from "react";
import isEqual from "lodash/isEqual";
import { moveArrayItem } from "tools/object";

function defaultCreateTableSettings(config, minSettingsListItems) {
  return config.slice(1).map((item, index, array) => ({
    checkboxProps: { disabled: array.length <= minSettingsListItems },
    name: item.headerName,
    selected: item.show,
  }));
}

function useTableSettings(options) {
  const {
    initialTableConfig,
    createTableSettings = defaultCreateTableSettings,
    minSettingsListItems,
  } = options;

  const initialTableSettings = useMemo(
    () => createTableSettings(initialTableConfig, minSettingsListItems),
    [createTableSettings, initialTableConfig, minSettingsListItems]
  );

  return {
    initialTableSettings,
    initialTableConfig,
  };
}

export function useDataGrid(options = {}) {
  const { minSettingsListItems = -1 } = options;

  const { initialTableSettings, initialTableConfig } = useTableSettings(
    options
  );

  const [tableSettings, setTableSettings] = useState(initialTableSettings);

  const onTableSettingsMenuItemClick = useCallback(
    (value) => () => {
      let selectedCount = 0;
      const result = tableSettings
        .map((item) => {
          const newItem =
            item.name === value
              ? {
                  ...item,
                  selected: !item.selected,
                }
              : item;

          if (newItem.selected) {
            selectedCount++;
          }

          return newItem;
        })
        .map((item) => ({
          ...item,
          checkboxProps: {
            ...item.checkboxProps,
            disabled: isCheckboxDisabled(item, selectedCount),
          },
        }));
      setTableSettings(result);
    },
    [minSettingsListItems, tableSettings]
  );

  const onTableSettingsSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newArray = moveArrayItem(tableSettings, oldIndex, newIndex);
        setTableSettings(newArray);
      }
    },
    [tableSettings]
  );

  const tableSettingsChanged = useMemo(
    () => !isEqual(initialTableSettings, tableSettings),
    [tableSettings, initialTableSettings]
  );

  const columnWidthCache = useRef({});

  const tableConfig = useMemo(() => {
    function setColumnWidth(item) {
      const currentWidth = columnWidthCache.current[item.headerName];
      return currentWidth
        ? {
            ...item,
            width: currentWidth,
          }
        : item;
    }

    const result = [
      setColumnWidth(initialTableConfig[0]),
      ...tableSettings.reduce((acc, item) => {
        if (item.selected) {
          const newItem = initialTableConfig.find(
            (i) => i.headerName === item.name
          );
          if (newItem) {
            return acc.concat(setColumnWidth(newItem));
          }
        }
        return acc;
      }, []),
    ];
    if (initialTableConfig.at(-1).type === "actionButtons") {
      result.push(initialTableConfig.at(-1));
    }
    return result;
  }, [tableSettings, initialTableConfig]);

  const onColumnWidthChange = useCallback(({ width, colDef }) => {
    columnWidthCache.current[colDef.headerName] = width;
  }, []);

  const isCheckboxDisabled = useCallback(
    (item, selectedCount) => {
      if (minSettingsListItems < 0) return item.checkboxProps.disabled;
      return selectedCount <= minSettingsListItems ? !!item.selected : false;
    },
    [minSettingsListItems]
  );

  return {
    tableConfig,
    tableSettings,
    tableSettingsChanged,
    onColumnWidthChange,
    onTableSettingsMenuItemClick,
    onTableSettingsSortEnd,
  };
}
