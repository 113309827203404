import { useMemo, Ref, RefCallback, MutableRefObject } from "react";

function setRef<T>(
  ref:
    | MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null
): void {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
export function useForkRef<T>(...refs: Ref<T>[]): RefCallback<T> {
  return useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }
    return (value) => {
      refs.forEach((ref) => {
        setRef(ref, value);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}
