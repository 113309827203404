import moment from "moment";
import {
    PlatformUIAnalyticsEvent,
    YarkonCommonEvents,
    YarkonEventsUserAttributes,
    YarkonEventsURLAttributes,
    YarkonEventsDeviceAttributes,
} from "@p81-common/yarkon-events-dtos";
import { AnalyticsEvents } from "./AnalyticsEvents";

import { links } from "data/constants"
import { Payload, UserObject } from "./analyticsTypes"
import { env } from "env";
import {
    default as requestWithSubTenantIfNeeded,
} from "store/api/organization/requestWithSubTenantIfNeeded";
import detectBrowser from "safervpn-storybook/src/tools/detectBrowser";

export default class PlatformAnalytics extends AnalyticsEvents {
    private readonly DESKTOP: YarkonEventsDeviceAttributes["device_type"] = "desktop";
    private readonly PRODUCER: string = "WEB_UI";
    private readonly METHOD: string = "post";
    private readonly ENDPOINT: string = "v1/analytics/publish";
    private readonly DEFAULT_ACTION: string = "Click Button";
    private readonly AWS_ID_HEADER: { [name: string]: boolean } = { "x-amz-apigw-id": true };
    private readonly requestPrefix: string;
    private readonly deviceAttributes: YarkonEventsDeviceAttributes = {
        browser_type: detectBrowser.name,
        browser_version: detectBrowser.version,
        browser_resolution: `${window.innerWidth}x${window.innerHeight}`,
        os_type: detectBrowser.os,
        device_type: this.DESKTOP,
    }
    private readonly urlAttributes: YarkonEventsURLAttributes = {
        url: document.URL,
    }
    private readonly common: Omit<YarkonCommonEvents, 'event_time' | 'event_type'> = {
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        environment: env.REACT_APP_ENVIRONMENT,
        producer: this.PRODUCER,
    }

    constructor() {
        super();
        this.requestPrefix = env.REACT_APP_API_URL || `${links.events_request_prefix}.${env.REACT_APP_DOMAIN}/api`;
    }

    async sendTrackEvent(event: PlatformUIAnalyticsEvent): Promise<void> {
        try {
            await requestWithSubTenantIfNeeded(
                this.METHOD,
                this.ENDPOINT,
                {
                    headers: this.AWS_ID_HEADER,
                    json: event,
                    prefixUrl: this.requestPrefix,
                })
        } catch (e) {
        }
    }

    extractEventData(payload: Payload): PlatformUIAnalyticsEvent {
        const { event_name, action = this.DEFAULT_ACTION, tag, label, sub_properties } = payload;
        return {
            ui_common: {
                common: {
                    ...this.common,
                    event_type: event_name,
                    event_time: moment().format(),
                },
                action: action,
                tag: tag,
                label: label,
                device_attributes: this.deviceAttributes,
                user_attributes: this.getUserAttributes(),
                url_attributes: this.urlAttributes,
            },
            additional_properties: {
                ...sub_properties,
            },
        };
    }

    private getUserAttributes: () => YarkonEventsUserAttributes = () => {
        return { id: this.config.user_id, type: this.config.user_type as YarkonEventsUserAttributes["type"] };
    }
}
