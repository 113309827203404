import i18n from "i18next";

export function setColumnsHandler(value, columnsList, minSelectedItems) {
  let selectedCount = 0;
  const updatedСolumnsList = columnsList.map((listItem) => {
    const newItem =
      listItem.name === value
        ? {
            ...listItem,
            selected: !listItem.selected,
          }
        : listItem;

    if (newItem.selected) {
      selectedCount++;
    }
    return newItem;
  });
  const result = updatedСolumnsList.map((item) => ({
    ...item,
    checkboxProps: {
      ...item.checkboxProps,
      disabled: selectedCount < minSelectedItems + 1 ? !!item.selected : false,
    },
  }));

  return result;
}

export function getTableConfigDisplay(columnsList, tableConfig) {
  return [
    tableConfig[0],
    ...columnsList.reduce((acc, item) => {
      if (item.selected) {
        const newItem = tableConfig.find((i) => i.headerName === item.name);
        if (newItem) {
          return acc.concat(newItem);
        }
      }
      return acc;
    }, []),
  ];
}

export function customDateTimeGenerator(time) {
  // Convert both dates to milliseconds
  const then = new Date(time).getTime();
  const now = new Date().getTime();
  const oneHour = 3600000;

  // Calculate the difference in milliseconds
  const difference = Math.abs(now - then);

  if (difference < oneHour) {
    const oneMin = 60000;
    const differenceInMins = Math.round(difference / oneMin);
    return i18n.t("activity:web_activity.min_ago", { differenceInMins });
  } else {
    return false;
  }
}

export const pageOptions = [
  { name: 50, selected: true },
  { name: 100, selected: false },
  { name: 250, selected: false },
  { name: 500, selected: false },
];

export function getPageOptions(currentQuery) {
  if (currentQuery) {
    return pageOptions.map((option) => ({
      ...option,
      selected: option.name === currentQuery,
    }));
  }
  return pageOptions;
}

export function columnSortHandler(array, from, to) {
  const newArray = [...array];
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = newArray.splice(from, 1);
    newArray.splice(endIndex, 0, item);
  }

  return newArray;
}

export function mapTableSettingsList(tableConfig) {
  return tableConfig.slice(1).map((item) => ({
    selected: true,
    name: item.title,
  }));
}

// parsers data to filter selects

export function parseNetworkToSelect(data) {
  if (data) {
    return data.map((d) => ({
      label: d.name,
      value: d.dns,
    }));
  } else return [];
}

export function parseMembers(members, memberIdentifier) {
  if (members) {
    return members.map((member) => ({
      label: member.firstName
        ? `${member.firstName} ${member.lastName || ""}`
        : member.email,
      value: member[memberIdentifier],
      emailVerified: member.emailVerified,
      initialsColor: member.initialsColor,
      initials: member.initials,
      icon: member.icon,
      terminated: member.terminated,
    }));
  } else return [];
}

export function parseGeneralDataToSelect(
  data,
  options = { labelKey: "name", valueKey: "_id" }
) {
  if (data) {
    const { labelKey, valueKey } = options;
    return data.map((d) => ({
      label: d[labelKey] || d.displayName || d.ip,
      value: d[valueKey] || d.number || d.id,
    }));
  } else return [];
}

export function parseRegionsToSelect(data, networks) {
  if (data) {
    return data.map((d) => ({
      label: `${d.name} (${
        networks?.find(({ id, _id }) => [id, _id].includes(d.network))?.name
      })`,
      value: d.number || d.id || d._id,
    }));
  } else return [];
}

export function getFilterValues(filter) {
  if (Array.isArray(filter)) {
    return filter.length ? filter.map((f) => f.value ?? f) : undefined;
  } else {
    return filter ? [filter] : undefined;
  }
}

export function toPercentage(data) {
  const result = {};
  const sum = Object.values(data).reduce((a, b) => a + b, 0);
  for (const item in data) {
    result[item] = sum ? Math.round((data[item] / sum) * 100) : 0;
  }
  return result;
}
