// ------------------------------------
// Constants
// ------------------------------------
export const ROOT_ACCOUNT_GET_REQUEST = "ROOT_ACCOUNT_GET_REQUEST";
export const ROOT_ACCOUNT_GET_REQUEST_SUCCESS =
  "ROOT_ACCOUNT_GET_REQUEST_SUCCESS";
export const ROOT_ACCOUNT_GET_REQUEST_FAILURE =
  "ROOT_ACCOUNT_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request() {
  return {
    type: ROOT_ACCOUNT_GET_REQUEST,
  };
}

export function requestSuccess(res) {
  return {
    type: ROOT_ACCOUNT_GET_REQUEST_SUCCESS,
    data: res,
  };
}

export function requestFailure(error) {
  return {
    type: ROOT_ACCOUNT_GET_REQUEST_FAILURE,
    error,
  };
}
