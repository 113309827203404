import { call, put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./uiPopupsPostActions";

function* uiPopupsPost(action) {
  try {
    let response = yield authorizedApi
      .post("ui-popups/" + action.payload)
      .json();
    yield put(actions.requestSuccess(response.data.user));

    if (action.resolve) {
      yield call(action.resolve);
    }
  } catch (e) {
    yield put(actions.requestFailure(e));

    if (action.reject) {
      yield call(action.reject, e);
    }
  }
}

function* saga() {
  yield takeEvery(actions.UI_POPUPS_POST_REQUEST, uiPopupsPost);
}

export default saga;
