export const ROOT = "";

export const SIGN_IN = ROOT + "/sign-in";

export const RESET_PASSWORD = ROOT + "/password-request";
export const RESET_PASSWORD_SENT = RESET_PASSWORD + "/sent";
export const RESET_PASSWORD_SELECT_NEW = RESET_PASSWORD + "/select-new";
export const RESET_PASSWORD_SUCCESS = RESET_PASSWORD + "/success";

export const INVITATION = ROOT + "/invitation";
export const INVITATION_SUCCESS = INVITATION + "/success";

export const WRONG_WORKSPACE = ROOT + "/wrong-workspace";
export const FIND_WORKSPACE = ROOT + "/find-workspace";
export const BLOCKED = ROOT + "/blocked";

export const APPLICATION_CONNECTION = ROOT + "/connect/:applicationId?";
