import classes from "./ErrorMessage.module.scss";
import cn from "classnames";
import PropTypes from "prop-types";
import { Typography } from "@perimeter_81/ui";
import { ReactSVG } from "react-svg";
import constants from "safervpn-storybook/src/data/constants.json";

function parseError({ error, title, description }) {
  if (error) {
    let errorData = error.response || error.request;
    return {
      title: errorData?.statusText || title || "Error",
      description: errorData?.data?.message || error.message,
    };
  }
  return { title, description };
}

export function ErrorMessage(props) {
  let {
    appearance = constants.appearance.NORMAL,
    className,
    iconUrl,
    small,
  } = props;

  let { title, description } = parseError(props);

  if (appearance === constants.appearance.SIMPLE) {
    return (
      <div
        className={cn(classes.container, classes.containerSimple, className)}
      >
        {iconUrl && <ReactSVG src={iconUrl} className={classes.icon} />}
        <div className={cn(classes.message, classes.messageSimple)}>
          <Typography variant="h4" className={classes.title}>
            {title.toString()}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.container, className)}>
      {iconUrl && <ReactSVG src={iconUrl} className={classes.icon} />}
      <Typography variant={small ? "4" : "3"} className={classes.title}>
        {title.toString()}
      </Typography>
      <Typography
        variant={small ? "body2" : "body1"}
        className={classes.description}
      >
        {description}
      </Typography>
    </div>
  );
}

ErrorMessage.propTypes = {
  iconUrl: PropTypes.string,
  error: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  small: PropTypes.bool,
  appearance: PropTypes.oneOf(Object.values(constants.appearance)),
};
