import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";
import App from "App";

// fixes https://github.com/webpack-contrib/extract-text-webpack-plugin/issues/456
// require('style-loader/lib/addStyles');
// require('css-loader/lib/css-base');

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

unregister();
