import { Suspense, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loading } from "@perimeter_81/ui";
import { ErrorMessage } from "components/common/ErrorMessage/ErrorMessage";
import { request as checkTenantGetRequest } from "store/api/checkTenantGet/checkTenantGetActions";
import { useWhitelabel } from "hooks";
import { env } from "env";
import { lazyWithRetry } from "tools/lazyWithRetry";
import { isCheckpointDomain } from "data/isCheckpointDomain";

let Deactivated = lazyWithRetry(() =>
  import("components/auth/Deactivated/Deactivated")
);

function CheckTenant(props) {
  const Component = isCheckpointDomain ? CheckTenantCP : CheckTenantP81;
  return <Component {...props} />;
}

function CheckTenantCP(props) {
  const { children } = props;
  const { loading, error, userData } = useSelector((s) => s.cpCheckTenantGet);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (userData) {
    return children;
  }

  return null;
}

function CheckTenantP81(props) {
  let { children, wrongWorkspaceUrl } = props;
  let checkTenantGet = useSelector((s) => s.checkTenantGet);

  let dispatch = useDispatch();
  let { replace } = useHistory();

  let { name: whitelabel } = useWhitelabel();
  useEffect(() => {
    dispatch(checkTenantGetRequest());
  }, [dispatch]);

  const isWrongTenant = checkTenantGet.error?.response?.status === 404;
  const isTerminated = checkTenantGet.data?.isTerminated;

  useEffect(() => {
    if (isWrongTenant) {
      if (whitelabel === "perimeter81") {
        window.location.href = env.REACT_APP_WRONG_WORKSPACE_URL;
      } else {
        replace(wrongWorkspaceUrl());
      }
    } else if (isTerminated) {
      window.location.href = `${env.REACT_APP_WRONG_WORKSPACE_URL}&allowTenantSelect=1`;
    }
  }, [isWrongTenant, whitelabel, replace, wrongWorkspaceUrl, isTerminated]);

  if (checkTenantGet.loading) {
    return <Loading />;
  }

  if (checkTenantGet.error && !isWrongTenant) {
    return <ErrorMessage error={checkTenantGet.error} />;
  }

  if (checkTenantGet.loaded) {
    let { appClientId, webClientId } = checkTenantGet.data.auth0 || {};
    let isAccountActivated = appClientId && webClientId;

    if (isAccountActivated) {
      return children;
    }
    return (
      <Suspense fallback={<Loading />}>
        <Deactivated />
      </Suspense>
    );
  }

  return null;
}

CheckTenant.propTypes = {
  children: PropTypes.any.isRequired,
  wrongWorkspaceUrl: PropTypes.func,
};

export default CheckTenant;
