import { request } from "store/api/customerGet/customerGetActions";
import { useResource } from "./useResource";
import { hasAccess, resources, access } from "tools/permissions";

export function useCustomer() {
  return useResource(
    "customerGet",
    request,
    hasAccess(resources.BILLING, access.READ)
  );
}
