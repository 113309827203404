import { all, put, takeEvery } from "redux-saga/effects";
import { authorizedApi } from "tools/apiConfigurator";
import * as actions from "./notificationsGetActions";
import { getI18nInstance } from "tools/i18n";
import { showNotif } from "@perimeter_81/ui";
import { findType } from "components/activity/HeaderNotifications/NotificationsPopup/NotificationList/HeaderNotification/NotificationMessage/helpers";
import { translateActivityProps } from "store/api/organization/activityLogsGet/activityHelper";

const ADD_USER_TO_GROUP = "api.activity.group.add.member";

function* notificationsGet(action) {
  try {
    let response = yield authorizedApi
      .get(`notifications${action.sinceId ? `?sinceId=${action.sinceId}` : ""}`)
      .json();
    response.data.forEach(translateActivityProps);
    yield put(
      actions.requestSuccess(
        response.data,
        response.data.length > 0
          ? response.data[response.data.length - 1]._id
          : null,
        response.unreadCount,
        response.page,
        response.pageTotal
      )
    );
  } catch (e) {
    yield put(actions.requestFailure(e));
  }
}

function* prepareReceiveNotification(action) {
  let { eventName, ...others } = action.notification;
  let activityType = findType(eventName);
  let I18nInstance = getI18nInstance();
  if (eventName === ADD_USER_TO_GROUP) return;
  let message = (
    <span
      dangerouslySetInnerHTML={{
        __html: I18nInstance.t(
          `activity:messages.${activityType.name}`,
          others
        ),
      }}
    />
  );

  yield showNotif(message, {
    ms: 5000,
    alertProps: {
      severity: "success",
      variant: "filled",
    },
  });
}

function* saga() {
  yield all([
    takeEvery(actions.NOTIFICATIONS_GET_REQUEST, notificationsGet),
    takeEvery(
      actions.NOTIFICATIONS_RECEIVE_NOTIFICATION,
      prepareReceiveNotification
    ),
  ]);
}

export default saga;
