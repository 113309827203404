// ------------------------------------
// Constants
// ------------------------------------
// prettier-ignore
export const NETWORKS_BEACONS_GET_REQUEST = 'NETWORKS_BEACONS_GET_REQUEST';
// prettier-ignore
export const NETWORKS_BEACONS_GET_REQUEST_SUCCESS = 'NETWORKS_BEACONS_GET_REQUEST_SUCCESS';
// prettier-ignore
export const NETWORKS_BEACONS_GET_REQUEST_FAILURE = 'NETWORKS_BEACONS_GET_REQUEST_FAILURE';

export const SOCKET_EVENT_DEPLOY_START = "SOCKET_EVENT_DEPLOY_START";
export const SOCKET_EVENT_DEPLOY_STATUS = "SOCKET_EVENT_DEPLOY_STATUS";
export const SOCKET_EVENT_DEPLOY_SUCCESS = "SOCKET_EVENT_DEPLOY_SUCCESS";
export const SOCKET_EVENT_DEPLOY_FAILED = "SOCKET_EVENT_DEPLOY_FAILED";

export const SOCKET_EVENT_NODE_CHANGE_STATE_START = "SOCKET_EVENT_NODE_CHANGE_STATE_START";
export const SOCKET_EVENT_NODE_CHANGE_STATE_STATUS = "SOCKET_EVENT_NODE_CHANGE_STATE_STATUS";
export const SOCKET_EVENT_NODE_CHANGE_STATE_SUCCESS = "SOCKET_EVENT_NODE_CHANGE_STATE_SUCCESS";
export const SOCKET_EVENT_NODE_CHANGE_STATE_FAILED = "SOCKET_EVENT_NODE_CHANGE_STATE_FAILED";

export const SOCKET_EVENT_REGION_CHANGE_STATE_START = "SOCKET_EVENT_REGION_CHANGE_STATE_START";
export const SOCKET_EVENT_REGION_CHANGE_STATE_STATUS = "SOCKET_EVENT_REGION_CHANGE_STATE_STATUS";
export const SOCKET_EVENT_REGION_CHANGE_STATE_SUCCESS = "SOCKET_EVENT_REGION_CHANGE_STATE_SUCCESS";
export const SOCKET_EVENT_REGION_CHANGE_STATE_FAILED = "SOCKET_EVENT_REGION_CHANGE_STATE_FAILED";

export const SOCKET_EVENT_DEPLOY_CUSTOM_START =
  "SOCKET_EVENT_DEPLOY_CUSTOM_START";
export const SOCKET_EVENT_DEPLOY_CUSTOM_STATUS =
  "SOCKET_EVENT_DEPLOY_CUSTOM_STATUS";
export const SOCKET_EVENT_DEPLOY_CUSTOM_SUCCESS =
  "SOCKET_EVENT_DEPLOY_CUSTOM_SUCCESS";
export const SOCKET_EVENT_DEPLOY_CUSTOM_FAILED =
  "SOCKET_EVENT_DEPLOY_CUSTOM_FAILED";

export const SOCKET_EVENT_DESTROY_START = "SOCKET_EVENT_DESTROY_START";
export const SOCKET_EVENT_DESTROY_STATUS = "SOCKET_EVENT_DESTROY_STATUS";
export const SOCKET_EVENT_DESTROY_SUCCESS = "SOCKET_EVENT_DESTROY_SUCCESS";
export const SOCKET_EVENT_DESTROY_FAILED = "SOCKET_EVENT_DESTROY_FAILED";

export const SOCKET_EVENT_ROUTETABLE_UPDATE_START =
  "SOCKET_EVENT_ROUTETABLE_UPDATE_START";
export const SOCKET_EVENT_ROUTETABLE_UPDATE_STATUS =
  "SOCKET_EVENT_ROUTETABLE_UPDATE_STATUS";
export const SOCKET_EVENT_ROUTETABLE_UPDATE_SUCCESS =
  "SOCKET_EVENT_ROUTETABLE_UPDATE_SUCCESS";
export const SOCKET_EVENT_ROUTETABLE_UPDATE_FAILED =
  "SOCKET_EVENT_ROUTETABLE_UPDATE_FAILED";

export const SOCKET_EVENT_LOCATION_ADD_START =
  "SOCKET_EVENT_LOCATION_ADD_START";
export const SOCKET_EVENT_LOCATION_ADD_STATUS =
  "SOCKET_EVENT_LOCATION_ADD_STATUS";
export const SOCKET_EVENT_LOCATION_ADD_SUCCESS =
  "SOCKET_EVENT_LOCATION_ADD_SUCCESS";
export const SOCKET_EVENT_LOCATION_ADD_FAILED =
  "SOCKET_EVENT_LOCATION_ADD_FAILED";

export const SOCKET_EVENT_LOCATION_CUSTOM_ADD_START =
  "SOCKET_EVENT_LOCATION_CUSTOM_ADD_START";
export const SOCKET_EVENT_LOCATION_CUSTOM_ADD_STATUS =
  "SOCKET_EVENT_LOCATION_CUSTOM_ADD_STATUS";
export const SOCKET_EVENT_LOCATION_CUSTOM_ADD_SUCCESS =
  "SOCKET_EVENT_LOCATION_CUSTOM_ADD_SUCCESS";
export const SOCKET_EVENT_LOCATION_CUSTOM_ADD_FAILED =
  "SOCKET_EVENT_LOCATION_CUSTOM_ADD_FAILED";

export const SOCKET_EVENT_LOCATION_REMOVE_START =
  "SOCKET_EVENT_LOCATION_REMOVE_START";
export const SOCKET_EVENT_LOCATION_REMOVE_STATUS =
  "SOCKET_EVENT_LOCATION_REMOVE_STATUS";
export const SOCKET_EVENT_LOCATION_REMOVE_SUCCESS =
  "SOCKET_EVENT_LOCATION_REMOVE_SUCCESS";
export const SOCKET_EVENT_LOCATION_REMOVE_FAILED =
  "SOCKET_EVENT_LOCATION_REMOVE_FAILED";

export const SOCKET_EVENT_SCALEUNIT_INCREASE_START = "SOCKET_EVENT_SCALEUNIT_INCREASE_START";
export const SOCKET_EVENT_SCALEUNIT_INCREASE_STATUS = "SOCKET_EVENT_SCALEUNIT_INCREASE_STATUS";
export const SOCKET_EVENT_SCALEUNIT_INCREASE_SUCCESS = "SOCKET_EVENT_SCALEUNIT_INCREASE_SUCCESS";
export const SOCKET_EVENT_SCALEUNIT_INCREASE_FAILED = "SOCKET_EVENT_SCALEUNIT_INCREASE_FAILED";

export const SOCKET_EVENT_SCALEUNIT_REDUCE_START = "SOCKET_EVENT_SCALEUNIT_REDUCE_START";
export const SOCKET_EVENT_SCALEUNIT_REDUCE_STATUS = "SOCKET_EVENT_SCALEUNIT_REDUCE_STATUS";
export const SOCKET_EVENT_SCALEUNIT_REDUCE_SUCCESS = "SOCKET_EVENT_SCALEUNIT_REDUCE_SUCCESS";
export const SOCKET_EVENT_SCALEUNIT_REDUCE_FAILED = "SOCKET_EVENT_SCALEUNIT_REDUCE_FAILED";

export const SOCKET_EVENT_NODE_ADD_START = "SOCKET_EVENT_NODE_ADD_START";
export const SOCKET_EVENT_NODE_ADD_STATUS = "SOCKET_EVENT_NODE_ADD_STATUS";
export const SOCKET_EVENT_NODE_ADD_SUCCESS = "SOCKET_EVENT_NODE_ADD_SUCCESS";
export const SOCKET_EVENT_NODE_ADD_FAILED = "SOCKET_EVENT_NODE_ADD_FAILED";

export const SOCKET_EVENT_NODE_CUSTOM_ADD_START =
  "SOCKET_EVENT_NODE_CUSTOM_ADD_START";
export const SOCKET_EVENT_NODE_CUSTOM_ADD_STATUS =
  "SOCKET_EVENT_NODE_CUSTOM_ADD_STATUS";
export const SOCKET_EVENT_NODE_CUSTOM_ADD_SUCCESS =
  "SOCKET_EVENT_NODE_CUSTOM_ADD_SUCCESS";
export const SOCKET_EVENT_NODE_CUSTOM_ADD_FAILED =
  "SOCKET_EVENT_NODE_CUSTOM_ADD_FAILED";

export const SOCKET_EVENT_NODE_REMOVE_START = "SOCKET_EVENT_NODE_REMOVE_START";
export const SOCKET_EVENT_NODE_REMOVE_STATUS =
  "SOCKET_EVENT_NODE_REMOVE_STATUS";
export const SOCKET_EVENT_NODE_REMOVE_SUCCESS =
  "SOCKET_EVENT_NODE_REMOVE_SUCCESS";
export const SOCKET_EVENT_NODE_REMOVE_FAILED =
  "SOCKET_EVENT_NODE_REMOVE_FAILED";

export const SOCKET_EVENT_TUNNEL_ADD_START = "SOCKET_EVENT_TUNNEL_ADD_START";
export const SOCKET_EVENT_TUNNEL_ADD_STATUS = "SOCKET_EVENT_TUNNEL_ADD_STATUS";
export const SOCKET_EVENT_TUNNEL_ADD_SUCCESS =
  "SOCKET_EVENT_TUNNEL_ADD_SUCCESS";
export const SOCKET_EVENT_TUNNEL_ADD_FAILED = "SOCKET_EVENT_TUNNEL_ADD_FAILED";

export const SOCKET_EVENT_TUNNEL_UPDATE_START =
  "SOCKET_EVENT_TUNNEL_UPDATE_START";
export const SOCKET_EVENT_TUNNEL_UPDATE_STATUS =
  "SOCKET_EVENT_TUNNEL_UPDATE_STATUS";
export const SOCKET_EVENT_TUNNEL_UPDATE_SUCCESS =
  "SOCKET_EVENT_TUNNEL_UPDATE_SUCCESS";
export const SOCKET_EVENT_TUNNEL_UPDATE_FAILED =
  "SOCKET_EVENT_TUNNEL_UPDATE_FAILED";

export const SOCKET_EVENT_TUNNEL_REMOVE_START =
  "SOCKET_EVENT_TUNNEL_REMOVE_START";
export const SOCKET_EVENT_TUNNEL_REMOVE_STATUS =
  "SOCKET_EVENT_TUNNEL_REMOVE_STATUS";
export const SOCKET_EVENT_TUNNEL_REMOVE_SUCCESS =
  "SOCKET_EVENT_TUNNEL_REMOVE_SUCCESS";
export const SOCKET_EVENT_TUNNEL_REMOVE_FAILED =
  "SOCKET_EVENT_TUNNEL_REMOVE_FAILED";

export const SOCKET_EVENT_TUNNEL_HA_ADD_START =
  "SOCKET_EVENT_TUNNEL_HA_ADD_START";
export const SOCKET_EVENT_TUNNEL_HA_ADD_STATUS =
  "SOCKET_EVENT_TUNNEL_HA_ADD_STATUS";
export const SOCKET_EVENT_TUNNEL_HA_ADD_SUCCESS =
  "SOCKET_EVENT_TUNNEL_HA_ADD_SUCCESS";
export const SOCKET_EVENT_TUNNEL_HA_ADD_FAILED =
  "SOCKET_EVENT_TUNNEL_HA_ADD_FAILED";

export const SOCKET_EVENT_TUNNEL_HA_UPDATE_START =
  "SOCKET_EVENT_TUNNEL_HA_UPDATE_START";
export const SOCKET_EVENT_TUNNEL_HA_UPDATE_STATUS =
  "SOCKET_EVENT_TUNNEL_HA_UPDATE_STATUS";
export const SOCKET_EVENT_TUNNEL_HA_UPDATE_SUCCESS =
  "SOCKET_EVENT_TUNNEL_HA_UPDATE_SUCCESS";
export const SOCKET_EVENT_TUNNEL_HA_UPDATE_FAILED =
  "SOCKET_EVENT_TUNNEL_HA_UPDATE_FAILED";

export const SOCKET_EVENT_TUNNEL_HA_REMOVE_START =
  "SOCKET_EVENT_TUNNEL_HA_REMOVE_START";
export const SOCKET_EVENT_TUNNEL_HA_REMOVE_STATUS =
  "SOCKET_EVENT_TUNNEL_HA_REMOVE_STATUS";
export const SOCKET_EVENT_TUNNEL_HA_REMOVE_SUCCESS =
  "SOCKET_EVENT_TUNNEL_HA_REMOVE_SUCCESS";
export const SOCKET_EVENT_TUNNEL_HA_REMOVE_FAILED =
  "SOCKET_EVENT_TUNNEL_HA_REMOVE_FAILED";

export const SOCKET_EVENT_SPLITTUNNELING_UPDATE_START =
  "SOCKET_EVENT_SPLITTUNNELING_UPDATE_START";
export const SOCKET_EVENT_SPLITTUNNELING_UPDATE_STATUS =
  "SOCKET_EVENT_SPLITTUNNELING_UPDATE_STATUS";
export const SOCKET_EVENT_SPLITTUNNELING_UPDATE_SUCCESS =
  "SOCKET_EVENT_SPLITTUNNELING_UPDATE_SUCCESS";
export const SOCKET_EVENT_SPLITTUNNELING_UPDATE_FAILED =
  "SOCKET_EVENT_SPLITTUNNELING_UPDATE_FAILED";

export const SOCKET_EVENT_CUSTOMDNS_UPDATE_START =
  "SOCKET_EVENT_CUSTOMDNS_UPDATE_START";
export const SOCKET_EVENT_CUSTOMDNS_UPDATE_STATUS =
  "SOCKET_EVENT_CUSTOMDNS_UPDATE_STATUS";
export const SOCKET_EVENT_CUSTOMDNS_UPDATE_SUCCESS =
  "SOCKET_EVENT_CUSTOMDNS_UPDATE_SUCCESS";
export const SOCKET_EVENT_CUSTOMDNS_UPDATE_FAILED =
  "SOCKET_EVENT_CUSTOMDNS_UPDATE_FAILED";

export const SOCKET_EVENT_APPLICATION_ADD_START =
  "SOCKET_EVENT_APPLICATION_ADD_START";
export const SOCKET_EVENT_APPLICATION_ADD_STATUS =
  "SOCKET_EVENT_APPLICATION_ADD_STATUS";
export const SOCKET_EVENT_APPLICATION_ADD_SUCCESS =
  "SOCKET_EVENT_APPLICATION_ADD_SUCCESS";
export const SOCKET_EVENT_APPLICATION_ADD_FAILED =
  "SOCKET_EVENT_APPLICATION_ADD_FAILED";

export const SOCKET_EVENT_APPLICATION_REMOVE_START =
  "SOCKET_EVENT_APPLICATION_REMOVE_START";
export const SOCKET_EVENT_APPLICATION_REMOVE_STATUS =
  "SOCKET_EVENT_APPLICATION_REMOVE_STATUS";
export const SOCKET_EVENT_APPLICATION_REMOVE_SUCCESS =
  "SOCKET_EVENT_APPLICATION_REMOVE_SUCCESS";
export const SOCKET_EVENT_APPLICATION_REMOVE_FAILED =
  "SOCKET_EVENT_APPLICATION_REMOVE_FAILED";

export const SOCKET_EVENT_APPLICATION_UPDATE_START =
  "SOCKET_EVENT_APPLICATION_UPDATE_START";
export const SOCKET_EVENT_APPLICATION_UPDATE_STATUS =
  "SOCKET_EVENT_APPLICATION_UPDATE_STATUS";
export const SOCKET_EVENT_APPLICATION_UPDATE_SUCCESS =
  "SOCKET_EVENT_APPLICATION_UPDATE_SUCCESS";
export const SOCKET_EVENT_APPLICATION_UPDATE_FAILED =
  "SOCKET_EVENT_APPLICATION_UPDATE_FAILED";

export const SOCKET_EVENT_NETFILTER_UPDATE_START =
  "SOCKET_EVENT_NETFILTER_UPDATE_START";
export const SOCKET_EVENT_NETFILTER_UPDATE_STATUS =
  "SOCKET_EVENT_NETFILTER_UPDATE_STATUS";
export const SOCKET_EVENT_NETFILTER_UPDATE_SUCCESS =
  "SOCKET_EVENT_NETFILTER_UPDATE_SUCCESS";
export const SOCKET_EVENT_NETFILTER_UPDATE_FAILED =
  "SOCKET_EVENT_NETFILTER_UPDATE_FAILED";
export const SOCKET_EVENT_NETFILTER_ROLLBACK_SUCCESS =
  "SOCKET_EVENT_NETFILTER_ROLLBACK_SUCCESS";

export const SOCKET_EVENT_ENABLE_FIREWALL_LOGS_START =
  "SOCKET_EVENT_ENABLEFIREWALLLOGS_START";
  export const SOCKET_EVENT_ENABLE_FIREWALL_LOGS_STATUS =
  "SOCKET_EVENT_ENABLEFIREWALLLOGS_STATUS";
  export const SOCKET_EVENT_ENABLE_FIREWALL_LOGS_SUCCESS =
  "SOCKET_EVENT_ENABLEFIREWALLLOGS_SUCCESS";
  export const SOCKET_EVENT_ENABLE_FIREWALL_LOGS_FAILED =
  "SOCKET_EVENT_ENABLEFIREWALLLOGS_FAILED";

export const SOCKET_EVENT_DNSFILTERING_UPDATE_SUCCESS =
  "SOCKET_EVENT_DNSFILTERING_UPDATE_SUCCESS";
export const SOCKET_EVENT_DNSFILTERING_UPDATE_START =
  "SOCKET_EVENT_DNSFILTERING_UPDATE_START";
export const SOCKET_EVENT_DNSFILTERING_UPDATE_FAILED =
  "SOCKET_EVENT_DNSFILTERING_UPDATE_FAILED";
export const SOCKET_EVENT_DNSFILTERING_UPDATE_STATUS =
  "SOCKET_EVENT_DNSFILTERING_UPDATE_STATUS";

export const SOCKET_EVENT_DNSFILTERING_REMOVE_SUCCESS =
  "SOCKET_EVENT_DNSFILTERING_REMOVE_SUCCESS";
export const SOCKET_EVENT_DNSFILTERING_REMOVE_START =
  "SOCKET_EVENT_DNSFILTERING_REMOVE_START";
export const SOCKET_EVENT_DNSFILTERING_REMOVE_FAILED =
  "SOCKET_EVENT_DNSFILTERING_REMOVE_FAILED";
export const SOCKET_EVENT_DNSFILTERING_REMOVE_STATUS =
  "SOCKET_EVENT_DNSFILTERING_REMOVE_STATUS";

// ------------------------------------
// Actions
// ------------------------------------

export function request() {
  return {
    type: NETWORKS_BEACONS_GET_REQUEST,
  };
}

export function requestSuccess(res, tenantId) {
  return {
    type: NETWORKS_BEACONS_GET_REQUEST_SUCCESS,
    data: res,
    tenantId,
  };
}

export function requestFailure(error) {
  return {
    type: NETWORKS_BEACONS_GET_REQUEST_FAILURE,
    error,
  };
}
