import { put, takeEvery, select } from "redux-saga/effects";
import requestWithSubTenantIfNeeded from "../requestWithSubTenantIfNeeded";
import * as actions from "./accountGetActions";
import {
  currentSubTenantSelector,
  currentTenantSelector,
} from "../requestWithSubTenantIfNeeded";
import {
  SOCKET_EVENT_DESTROY_SUCCESS,
  SOCKET_EVENT_LOCATION_ADD_START,
  SOCKET_EVENT_LOCATION_ADD_FAILED,
  SOCKET_EVENT_LOCATION_CUSTOM_ADD_START,
  SOCKET_EVENT_LOCATION_CUSTOM_ADD_FAILED,
  SOCKET_EVENT_LOCATION_REMOVE_SUCCESS,
  SOCKET_EVENT_NODE_REMOVE_SUCCESS,
  SOCKET_EVENT_SCALEUNIT_INCREASE_START,
  SOCKET_EVENT_SCALEUNIT_INCREASE_FAILED,
  SOCKET_EVENT_SCALEUNIT_REDUCE_START,
  SOCKET_EVENT_SCALEUNIT_REDUCE_FAILED,
  SOCKET_EVENT_NODE_ADD_START,
  SOCKET_EVENT_NODE_ADD_FAILED,
  SOCKET_EVENT_NODE_CUSTOM_ADD_START,
  SOCKET_EVENT_NODE_CUSTOM_ADD_FAILED,
  SOCKET_EVENT_DEPLOY_START,
  SOCKET_EVENT_DEPLOY_SUCCESS,
  SOCKET_EVENT_DEPLOY_FAILED,
  SOCKET_EVENT_DEPLOY_CUSTOM_START,
  SOCKET_EVENT_DEPLOY_CUSTOM_FAILED,
  SOCKET_EVENT_APPLICATION_ADD_START,
  SOCKET_EVENT_APPLICATION_ADD_SUCCESS,
  SOCKET_EVENT_APPLICATION_ADD_FAILED,
  SOCKET_EVENT_APPLICATION_REMOVE_SUCCESS,
  SOCKET_EVENT_NETFILTER_UPDATE_START,
  SOCKET_EVENT_NETFILTER_UPDATE_SUCCESS,
  SOCKET_EVENT_NETFILTER_UPDATE_FAILED,
  SOCKET_EVENT_NETFILTER_ROLLBACK_SUCCESS,
  SOCKET_EVENT_ENABLE_FIREWALL_LOGS_START,
  SOCKET_EVENT_ENABLE_FIREWALL_LOGS_STATUS,
  SOCKET_EVENT_ENABLE_FIREWALL_LOGS_SUCCESS,
  SOCKET_EVENT_ENABLE_FIREWALL_LOGS_FAILED
} from "../networksBeaconsGet/networksBeaconsGetActions";
import { CURRENT_CHILD_ORGANIZATION_SET } from "store/domain/currentChildOrganization/currentChildOrganizationActions";

function* accountGet(action) {
  try {
    if (action.data?.networkBeacon?.tenantId) {
      let tenantId = yield select(currentSubTenantSelector);

      if (!tenantId) {
        tenantId = yield select(currentTenantSelector);
      }
      if (action.data.networkBeacon.tenantId !== tenantId) {
        return;
      }
    }
    let response = yield requestWithSubTenantIfNeeded("get", "account");

    yield put(actions.requestSuccess(response.data));
  } catch (e) {
    yield put(actions.requestFailure(e));
  }
}

function* saga() {
  yield takeEvery(
    [
      actions.PROFILE_ACCOUNT_GET_REQUEST,
      CURRENT_CHILD_ORGANIZATION_SET,
      SOCKET_EVENT_DEPLOY_SUCCESS,
      SOCKET_EVENT_DESTROY_SUCCESS,
      SOCKET_EVENT_LOCATION_ADD_START,
      SOCKET_EVENT_LOCATION_ADD_FAILED,
      SOCKET_EVENT_LOCATION_REMOVE_SUCCESS,
      SOCKET_EVENT_LOCATION_CUSTOM_ADD_START,
      SOCKET_EVENT_LOCATION_CUSTOM_ADD_FAILED,
      SOCKET_EVENT_SCALEUNIT_INCREASE_START,
      SOCKET_EVENT_SCALEUNIT_INCREASE_FAILED,
      SOCKET_EVENT_SCALEUNIT_REDUCE_START,
      SOCKET_EVENT_SCALEUNIT_REDUCE_FAILED,
      SOCKET_EVENT_NODE_REMOVE_SUCCESS,
      SOCKET_EVENT_NODE_ADD_START,
      SOCKET_EVENT_NODE_ADD_FAILED,
      SOCKET_EVENT_NODE_CUSTOM_ADD_START,
      SOCKET_EVENT_NODE_CUSTOM_ADD_FAILED,
      SOCKET_EVENT_DEPLOY_START,
      SOCKET_EVENT_DEPLOY_FAILED,
      SOCKET_EVENT_DEPLOY_CUSTOM_START,
      SOCKET_EVENT_DEPLOY_CUSTOM_FAILED,
      SOCKET_EVENT_APPLICATION_ADD_START,
      SOCKET_EVENT_APPLICATION_ADD_SUCCESS,
      SOCKET_EVENT_APPLICATION_ADD_FAILED,
      SOCKET_EVENT_APPLICATION_REMOVE_SUCCESS,
      SOCKET_EVENT_NETFILTER_UPDATE_START,
      SOCKET_EVENT_NETFILTER_UPDATE_SUCCESS,
      SOCKET_EVENT_NETFILTER_UPDATE_FAILED,
      SOCKET_EVENT_NETFILTER_ROLLBACK_SUCCESS,
      SOCKET_EVENT_ENABLE_FIREWALL_LOGS_START,
      SOCKET_EVENT_ENABLE_FIREWALL_LOGS_STATUS,
      SOCKET_EVENT_ENABLE_FIREWALL_LOGS_SUCCESS,
      SOCKET_EVENT_ENABLE_FIREWALL_LOGS_FAILED
    ],
    accountGet
  );
}

export default saga;
