// ------------------------------------
// Constants
// ------------------------------------
export const USERS_ME_GET_REQUEST = "USERS_ME_GET_REQUEST";
export const USERS_ME_GET_REQUEST_SUCCESS = "USERS_ME_GET_REQUEST_SUCCESS";
export const USERS_ME_GET_REQUEST_FAILURE = "USERS_ME_GET_REQUEST_FAILURE";
export const USERS_ME_GET_RESET = "USERS_ME_GET_RESET";

// ------------------------------------
// Actions
// ------------------------------------
export function request() {
  return {
    type: USERS_ME_GET_REQUEST,
  };
}

export function requestSuccess(res) {
  return {
    type: USERS_ME_GET_REQUEST_SUCCESS,
    data: res,
  };
}

export function requestFailure(error) {
  return {
    type: USERS_ME_GET_REQUEST_FAILURE,
    error,
  };
}

export function reset() {
  return {
    type: USERS_ME_GET_RESET,
  };
}
