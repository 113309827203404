import { useRef, useState, useEffect } from "react";

export function useMeasure() {
  let ref = useRef();
  let [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  let [ro] = useState(() => {
    if (window.ResizeObserver) {
      return new ResizeObserver(([entry]) => set(entry.contentRect));
    }
    return null;
  });
  useEffect(() => {
    if (ref.current) {
      if (ro) {
        ro.observe(ref.current);
        return () => ro.disconnect();
      }
      set(ref.current.getBoundingClientRect());
    }
  }, [ro]);
  return [ref, bounds];
}
