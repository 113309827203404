// ------------------------------------
// Constants
// ------------------------------------
export const WHITELABEL_GET_REQUEST = "WHITELABEL_GET_REQUEST";
export const WHITELABEL_GET_REQUEST_SUCCESS = "WHITELABEL_GET_REQUEST_SUCCESS";
export const WHITELABEL_GET_REQUEST_FAILURE = "WHITELABEL_GET_REQUEST_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export function request(searchParams) {
  return {
    type: WHITELABEL_GET_REQUEST,
  };
}

export function requestSuccess(data) {
  return {
    type: WHITELABEL_GET_REQUEST_SUCCESS,
    data,
  };
}

export function requestFailure(error) {
  return {
    type: WHITELABEL_GET_REQUEST_FAILURE,
    error,
  };
}
